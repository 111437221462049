import { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Box, Button, Card, Grid,Avatar, Chip, CardActions, CardContent, CardMedia, Typography, } from '@mui/material';

function ProfilePreview() {

 const [userData, setUserData] = useState(null);
 const [tags, setTags] = useState([]); // Add this line
 const [DOB, setDOB] = useState(''); 
 const decoded = jwt_decode(localStorage.getItem('userToken'));
 const [loading, setLoading] = useState(true);

 function calculateAge(birthdate) {
  const birthDate = new Date(birthdate);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();
 
  if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
 
  return age;
 }

 useEffect(() => {
     const fetchData = async () => {
       try {
         const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
         if(await response.data) {
           setUserData(response.data);
           if (await response.data.PIT) {
             setTags(response.data.PIT.split(','));
           }
           setDOB(calculateAge(await response.data.Constants.DOB))
           setLoading(false)
         }
       } catch (error) {
         console.error(error);
       }
     };
     fetchData();
 }, [decoded.id]);


 const buttonStyle={
  display: 'flex',
  width: '343px',
  padding: '16px 32px',
  marginTop: '3vh',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '100px',
  backgroundColor: '#D8234C',
  color:'white'
}

return(

<Grid
container
justifyContent="center"
alignItems="top"
style={{ minHeight: '100vh', marginTop:'2.5vh' }}

>
<Box>
{loading ? <></> :<>
<Typography variant="h5" align="center" component="div" sx={{mt:'20px',mb:'20px', color:'#D8234C'}}>
         Display Profile
       </Typography>
 <Card sx={{m:'25px', backgroundColor:'#Fdfcef'}}>
 <CardContent>
   <Grid container>
     <Grid item xs={4}>
       <Avatar src={userData.face} sx={{height:'90px', width:'90px'}} alt={userData.Constants.username} />
     </Grid>
     <Grid item xs={8}>
       <Typography variant="h5" component="div">
         {userData.Constants.username}
       </Typography>
       <Typography variant="body" component="div">
         {DOB} | {userData.Practs.MaritalStatus} | {userData.Location.City} 
       </Typography>
       <Typography variant="body"  sx={{mb:'15px'}}>
         {userData.Employeement.StateOfEmployment} | {userData.Employeement.Profession && userData.Employeement.Profession} | {userData.Employeement.NatureOfEmployment==="Employed with Private Sector" || userData.Employeement.NatureOfEmployment==="Employed with Government/Public/Semi-Govt Sector" || userData.Employeement.NatureOfEmployment==="Employed with Not-for-profit/NGO Sector" ? "Employed" : 
         userData.Employeement.NatureOfEmployment==="Self-Employed Professional" ? "Self-Employed" :
         userData.Employeement.NatureOfEmployment==="Independent Consultant" ? "Consultant" :
         userData.Employeement.NatureOfEmployment==="Founder/Co-founder" || userData.Employeement.NatureOfEmployment==="Manage Own Business" || userData.Employeement.NatureOfEmployment==="Manage Not-for-profit Enterprise/NGO" ? "Buisness" : ""
         }
          {tags.slice(-2).map((tag, index) => (
            " | "+tag.trim().split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
           ))} 
       </Typography>
       <Typography variant="body2" color="text.secondary" sx={{mb:'15px'}}>
          {/* {tags.slice(-2).map((tag, index) => (
            <Chip key={index} size="small" label={tag.trim()}  sx={{mr:'5px'}}/>
           ))} */}
       </Typography>
     </Grid>
   </Grid>
   <Typography variant="body2" color="text.secondary">
     {userData.BIO}
    </Typography>
 </CardContent>
</Card>

<Grid container direction="column" justifyContent="center" alignItems="center">

<Button style={buttonStyle} alignItems="center" onClick={() => { window.location.href="/myProfile" }}>Go to my Profile</Button>
</Grid>

</>
}

</Box></Grid>
)
}




export default ProfilePreview;