import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import {Card, Grid, Box, CardContent, Typography, Button, Divider,
        Rating, CircularProgress, Stack } from '@mui/material';
import { green } from '@mui/material/colors';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import SmoothScroll from 'smooth-scroll';
// import selectEff from process.env.PUBLIC_URL + '/audios/testFeedback.mp3';


function LifeSatisfaction() {
  const [userData, setUserData] = useState(null);
  const [phase, setPhase] = useState(0);
  const [values, setValues] = useState(Array(5).fill(0));
  const [Questions, setQuestions] = useState([
    "In most ways my life is close to my ideal.",
    "The conditions of my life are excellent.",
    "I am satisfied with my life.",
    "So far I have gotten the important things I want in life. ",
    "If I could live my life over, I would change almost nothing."
  ]);

  const playAudio = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/testFeedback.mp3');
    audio.volume = 0.3; // Set volume to 50%
    audio.play();
   }
   const playSubmit = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/submit.mp3');
    audio.volume = 0.5; // Set volume to 50%
    audio.play();
    audio.addEventListener('ended', () => {
      scoot(1);
    });
   }
   
 
  const handleChange = (index) => (event, newValue) => {
    setValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });
  };

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
  
    const decoded = jwt_decode(userToken);
    const FetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        if (response.data && response.data.FirstBoot === false) {
          window.location.href='/MatchStackPage';
        }
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    FetchData();
  }, []); 


  const init = async ()=>{
    setPhase(1);
    }
    var scroll = new SmoothScroll('a[href*="#"]', {
      speed: 3700,
      easing: 'easeInOutCubic',
     });

    const scoot = async (i) =>{
      let doner=true;
      let score = values.reduce((accumulator, currentValue) => {
          if(currentValue===0){doner=false}
        return accumulator + currentValue;
      }, 0);
      if(doner===true){
        const result = score>1 && score<=9 ? "Extremely dissatisfied | score:"+score:
                        score>9 && score<=14 ? "Dissatisfied | score:"+score:
                        score>14 && score<=19 ? "Slightly dissatisfied | score:"+score:
                        score>19 && score<=24 ? "Slightly satisfied | score:"+score:
                        score>24 && score<=29 ? "Satisfied | score:"+score:
                        score>29 && score<=35 ? "Extremely satisfied | score:"+score: "INVALID RESULT | score:"+score
        // setPhase(7)
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
    
          const body = {
            id: userData._id,
            score: result,
            test:"LS"
          };
          const res = await axios.post(`${process.env.REACT_APP_SERVER}user/update`, body, config );
        window.location.href='/State';

        } catch (err) {
          alert('Internal Error Occured!')
          window.location.href="/"
        }
      }
      else{
        alert("Please answer all questions")
      }
    }

  const buttonStyle={
    display: 'flex', width: '343px', padding: '16px 32px', marginTop: '3vh',
    flexDirection: 'column', alignItems: 'center', gap: '12px',
    borderRadius: '100px', backgroundColor: '#D8234C', color:'white'
  }
  // const isAuthenticated = !!localStorage.getItem('userToken');

 return(
<div>
{ phase===0 ? // Declaration

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '70vh' }} >
    <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            <SentimentVerySatisfiedIcon /> Satisfaction with Life <br/>
            </Typography>
            <Typography gutterBottom variant="body1" align="center" component="div" style={{color:'grey'}}>
          The questionnaire is based on the work of 
          <span style={{color:'#D8234C'}}> Diener, E., Emmons, R. A., Larsen, R. J., &
Griffin, S.</span> (1985). The Satisfaction with Life
Scale, Journal of Personality Assessment, 49, 71-75.
          <br/><br/>
          <span style={{color:'#D8234C'}}> Duration: 1-2 mins</span><br/><br/>
          <Button onClick={() => init()}>Start</Button>
          </Typography>
      </CardContent>
    </Card>
</Grid>

: phase===1 ? // Page 1

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '20vh' }} >
  <Box sx={{ '& > legend': { mt: 2 },}} component='div' style={{ margin:'10px' }}>
    <Typography variant="h5" component="div" sx={{ flexGrow: 1}} style={{ textAlign: 'center' }} >
       <b> Satisfaction with Life </b>
    </Typography>
    <Typography align='left' style={{color:'grey', margin:'5px'}}>Please select the answer that best represents your view. Answer ALL questions.</Typography>
    <hr/>
{Questions !=null ? ( <>
    <Stack  spacing={2} divider={<Divider flexItem />}>
      { Questions.map((Question, index) => (
        <div style={{ padding: '20px' }}>
          <Typography key={`Q${index}`} component="legend">{Question}</Typography>
    <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px'}} >
      <Rating key={`rating${index}`}  name={`rating${index}`}  value={values[index]}  max={7}
              style={{ color: '#D8234C', fontSize: '2.4em' }} icon={<CircleIcon fontSize="inherit" />}
              emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />} onChange={handleChange(index)} 
              onClick={() => {
                scroll.animateScroll(window.scrollY + 190);
                // playAudio();
               }}
              highlightSelectedOnly />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Disagree</Typography>
            <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Agree</Typography>
          </Box>
      </div>
))}
</Stack>
<Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px', marginBottom:'40px'}} >
<Button style={buttonStyle} onClick={()=>{playSubmit();}}> Submit </Button>
</Box>
</> ) : (<Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}> <CircularProgress /> </Box> )}
</Box>
<Divider/>
</Grid>

: phase===5 ?
(
  <Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}>
      <CircularProgress />
    </Box>
)
:
<Grid
container
justifyContent="center"
alignItems="center"
style={{ minHeight: '70vh' }}
>
<Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
<CardContent>
<Typography gutterBottom variant="h5" align="center" component="div">
<SentimentVerySatisfiedIcon sx={{color: green[500]}} /> <b> Satisfaction with Life</b>
</Typography>
<Typography gutterBottom variant="h6" align="left" component="div">
    Excellent! Let's proceed to the next one.<br/>
You can return to it anytime. Once you login,
the app will bring you here directly.
    </Typography>
    <Button component={Link} to={'/State'}> Next</Button>
</CardContent>
</Card>
</Grid>
} 
</div>
 )
}

export default LifeSatisfaction;
