import * as React from 'react';
import { Paper, BottomNavigationAction, BottomNavigation } from '@mui/material';
import AccountBoxIcon  from '@mui/icons-material/AccountBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom';

export default function MobNav() {
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    <BottomNavigation sx={{ width: '100%' }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="People"
        value="People"
        icon={<AccountBoxIcon />}
        component={Link} to={'/MatchStackPage'}
      />
      <BottomNavigationAction
        label="Matches"
        value="Matches"
        icon={<FavoriteIcon />}
        component={Link} to={'/MatchBox'}
      />
      <BottomNavigationAction
        label="Meets"
        value="Meets"
        icon={<CalendarMonthIcon />}
        component={Link} to={'/EMeets'}

      />
    </BottomNavigation>
    </Paper>
  );
}