import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Menu, MenuItem, IconButton, Typography, Toolbar, AppBar, Avatar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';


function Navo() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dp, setDP] = useState(null)
  const decoded = jwt_decode(localStorage.getItem('userToken'));

  useEffect(() => {
      const fetchDP = async () => {
        if(!!localStorage.getItem('userDP')){
          setDP(localStorage.getItem('userDP'))
        }else{
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}user/dp/${decoded.id}`);
            if (await response.data) {
              localStorage.removeItem('userDP');
              localStorage.setItem('userDP', response.data);
              setDP(JSON.stringify(await response.data))
            }
          } catch (error) {
            console.error(error);
          }
  }
        }

      fetchDP();
  }, [decoded.id]);


    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = () => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userDP');

      setAnchorEl(null);
      window.location.href="/"
    };
   
return(
  
      <AppBar position="sticky" style={{backgroundColor:'#D8234C'}}>
        <Toolbar>
          <Typography variant="h6" style={{"textDecoration":'none', "color":"white"}} sx={{ flexGrow: 1 }}>
            Matchworthee<sup style={{fontSize:'.65em'}}>Alpha-{process.env.REACT_APP_VERSION}</sup>
          </Typography>
            <div>
            {!!localStorage.getItem('userDP') ? (
              <Avatar
                alt=":)"
                src={localStorage.getItem('userDP')}
                onClick={handleMenu}
                style={{ border: '1.5px solid black' }}
                sx={{m:'5px'}}
              />
              ) : (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              )}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose} component={Link} to={'/Settings'}>
                Settings
                </MenuItem> */}
                <MenuItem onClick={handleClose} component={Link} to={'/MyProfile'}>
                My Profile
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/Share'}>
                Share
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                Log Out
                </MenuItem>

              </Menu>
            </div>
        </Toolbar>
      </AppBar>
)}

export default Navo;