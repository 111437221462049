import React from 'react';
// import { Link, Route, Navigate } from 'react-router-dom';

function MatchBox() {
  // const isAuthenticated = !!localStorage.getItem('userToken');
 return(
  <>
  
  <h1>All Confirmed Matches are hear !</h1>
  </>
 )
}

export default MatchBox;
