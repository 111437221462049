import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { CameraAlt } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Checkbox from '@mui/joy/Checkbox';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import {Button} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Drawer from '@mui/joy/Drawer';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddCircle from '@mui/icons-material/AddCircle';

function PIT() {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({
    PIT: ''});
  const [bioData, setBioData] = useState(null);
  const [bioSection, setBioSec] = useState(false);
  const [bioDrawer, setBioDrawer] = useState(false);
  const [Tadder, setTadder] = useState('');
  const [Tags, setTags]= useState([
  'Adventurous',
  'Ambitious',
  'Cheerful',
  'Creative',
  'Down to Earth',
  'Extrovert',
  'Friendly',
  'Helpful',
  'Intelligent',
  'Introvert',
  'Kind',
  'Lively',
  'Matured',
  'Playful',
  'Sensitive',
  'Simple',
  'Sincere',
  'Understanding',
  'Warm'
]);
    
    const decoded = jwt_decode(localStorage.getItem('userToken'));
    const handleChange = (e) => { setFormState({ ...formState, [e.target.name]: e.target.value });};
    const ClickPic = async () =>{ console.log("Click Picture Triggered")};
    
    const handleSubmit = async (e) => { e.preventDefault(); try {
      if(selected.length===5){
        setBioDrawer(true);

      }else{
        alert('Please select 5 Tags to continue!')
      }
    } catch (err) { console.log(err.response.data) }
    };

    const generateProfile = async () => { try {
        setBioSec(true);
      const config = { headers: { 'Content-Type': 'application/json', }, };
      console.log(selected)
      let PITdefDat = selected.slice(0, -2).toString();
      let PITDat = selected.toString();
      let newFormState = { ...formState, 'PIT': PITDat };
      setFormState(newFormState);
      const body = { id: userData._id, ...newFormState };
      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
      if(res.status===200){ 
          console.log("tags saved!")
          const bio = await axios.post(`${process.env.REACT_APP_SERVER}user/BioGen`, {'PITdef': PITdefDat}, config );
          setBioData(await bio.data);
      }
    } catch (err) { console.log(err.response.data) }
    };

const updateBIO = async ()=>{
  try {
  const config = { headers: { 'Content-Type': 'application/json', }, };
  let newFormState = { ...formState, 'BIO': bioData, 'PITs':true };
  const body = { id: userData._id, ...newFormState };
  await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
    window.location.href='/Biometric';
} catch (err) { console.log(err.response.data) }
}

  useEffect(() => {
      const fetchData = async () => { try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        if (response.data && response.data.StageState.PITs === true) {
          window.location.href='/Biometric';
        }
        setUserData(response.data);
        } catch (error){ console.error(error); } }; fetchData(); }, [decoded.id]);

const buttonStyle={
  display: 'flex', width: '343px', height: '51px', justifyContent: 'center', marginTop:'20px',
  marginBottom:'20px', borderRadius:'30px', alignItems: 'center', flexShrink: '0', backgroundColor:'#D8234C', color:'white' }
 
return (
  <Box style={{marginTop:'3vh'}} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3, borderRadius: 2 }}>
    <form onSubmit={handleSubmit}>        
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <div>
        <Typography level="title"  id="fav-movie" mb={6}>
        Select from the list below 5 personality tags
you believe describe you accurately enough.
Our AI will automatically use these words to
generate your display bio
        </Typography>
        <Box
          role="group"
          aria-labelledby="fav-movie"
          sx={{ maxWidth: 'md', justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 1 }}
        >
          {Tags
.map((name) => {
            const checked = selected.includes(name);
            return (
              <Chip
              key={name}
              variant="plain"
              size="lg"
              style={{ color: checked ? '#D8234C' : '#000000' }}
              startDecorator={
                checked && <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none' }} />
              }
            >
              <Checkbox
                variant="outlined"
                style={{ color: checked ? '#D8234C' : '#000000' }}
                disableIcon
                overlay
                label={name}
                checked={checked}
                onChange={(event) => {
                  setSelected((names) =>
                    !event.target.checked
                      ? names.filter((n) => n !== name)
                      : names.length<5 ? [...names, name] : names.filter((n) => n !== name),
                  );
                }}
              />
            </Chip>
            
            );
          })}
        </Box>
      </div>
    </Box>
    <Button variant="contained"  style={buttonStyle} type="submit">({selected.length}/5) Next</Button>
</form><br/><br/>
<Drawer
 anchor='bottom'
 open={bioDrawer}
 onClose={(event, reason) => {
  if (reason !== 'backdropClick') {
    setBioDrawer(false);
  }
}}
 size="lg"
 variant="soft"

>
<Box sx={{ 
  margin: [1, 2, 3, 4],
 padding: [1, 2, 3, 4],
}}>

{ bioSection===false ? (
    <>

          <Typography level="title-lg" id="fav-movie" mb={2}>
          Your Personality Tags
          </Typography>
<Box
          role="group"
          aria-labelledby="fav-movie"
          sx={{ maxWidth: 'md', justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 1, 
          mt: 2,
          mb: 5
        }}
        >
          {selected
            .map((name) => {
            return (
              <Chip
              key={name}
              variant="plain"
              size="lg"
              style={{ color:'#D8234C'}}
              startDecorator={
                 <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none' }} />
              }
            >
              <Checkbox
                variant="outlined"
                style={{ color: '#D8234C' }}
                disableIcon
                overlay
                label={name}
              />
            </Chip>
            
            );
          })}
        </Box>

<div>

<Typography level="title" id="fav-movie" mb={2}>
You can add up to two words or phrases of your choice to
describe yourself (max 22 characters, no special characters or
numerals allowed)
</Typography>
        <div>
        <TextField
        label="Add Tags"
        value={Tadder}
        onChange={(event) => event.target.value !=null ? setTadder(event.target.value) : alert('Empty Tag cannot be added!')}
        fullWidth
        sx={{
        "& .MuiOutlinedInput-root.Mui-focused": {
        "& fieldset": {
          borderColor: '#D8234C',
        },
        },
        "& label.Mui-focused": {
        color: '#D8234C',
        },
        }}
        InputProps={{
        endAdornment: (
        <InputAdornment position="end">
          <IconButton
            sx={{ color: '#D8234C' }}
            onClick={() => {
              const trimmedTadder = Tadder.trim();
              if (trimmedTadder !== '') {
              if (selected.length < 7) {
                setSelected([...selected, Tadder]);
                setTadder('')
              } else {
                alert('Can\'t add more than 2 Tags');
              }} else {
                alert('Empty Tag cannot be added!');
            }
            }}
          >
            <AddCircle />
          </IconButton>
        </InputAdornment>
        ),
        }}
        />
        </div>
        </div>
        <Button variant="contained"  style={buttonStyle} onClick={generateProfile}>Generate My Display Bio</Button>
        </>):(<></>)}


        { bioSection ? (
    <>
        <Typography gutterBottom variant="h5" component="div">
        
        {bioData ? (
         <p>Your Display Bio</p>) : (
        <p>Generating Your Display Bio...</p>
          )}
          </Typography>
        <Typography variant="body2" color="text.secondary">
      {
        bioData ? (<>
          <p>{bioData}</p>
          <Button size="small" onClick={updateBIO} sx={{color: '#D8234C'}}>Save</Button>
          </>) : (<>
          <Box>
            <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
          </Box>
        
        </> )
      }
        </Typography>
    </>
    ): (<></>)
}
</Box> 
</Drawer>
</Box>
);}

export default PIT;