import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {  Box, Typography, Avatar, Button, Skeleton, Badge, Tooltip, Snackbar, Alert,
          Table, TableBody, TableCell, TableContainer, TableRow, Grid } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';

function MyProfile() {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');

  const decoded = jwt_decode(localStorage.getItem('userToken'));

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
              setUserData(response.data);
            } catch (error) {
              console.error(error);
          }
      };
      fetchData();
  }, [decoded.id]);

  const handleEditProfile = async () =>{
    window.location.href="/CompleteProfile";
  }

  const ClickPic = async () =>{
   window.location.href="/Biometric";
  };
  
  const handleConstants =(what,type)=>() => {
    setMessage(what);
    setType(type);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const buttonStyle={
    display: 'flex', width: '343px', height: '51px', justifyContent: 'center', marginTop:'20px',
    borderRadius:'30px', alignItems: 'center', flexShrink: '0', backgroundColor:'#D8234C', color:'white' }
   
  return (
        <Box style={{marginTop:'3vh'}} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3, borderRadius: 2 }}>
                  
          {/* <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClick={ClickPic}
            badgeContent={
              <Tooltip title="Change Profile Picture">
              <CameraAlt />
            </Tooltip>
            } > */}
        <Avatar src={userData ? userData.face : '*'} sx={{ width: 100, height: 100 }} />
          {/* </Badge> */}
          <Typography variant="h4">{userData ? userData.Constants.username : <Skeleton variant="text" width={180} sx={{ fontSize: '2rem' }} />}</Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                    <TableRow onClick={handleConstants('Email can\'t be changed!','error')}>
                      <TableCell><b>Email:</b></TableCell>
                      <TableCell align="left">{userData ? userData.Constants.email : <Skeleton  animation="wave"  variant="text" width={180} sx={{ fontSize: '1rem' }} />}</TableCell>
                    </TableRow>
                    <TableRow onClick={handleConstants('Phone No. can\'t be changed!','error')}>
                      <TableCell><b>Phone:</b></TableCell>
                      <TableCell align="left">{userData ? userData.Constants.phone : <Skeleton  animation="wave"  variant="text"  width={180}  sx={{ fontSize: '1rem' }} />}</TableCell>
                    </TableRow>
                    <TableRow  onClick={handleConstants('Gender can\'t be changed!','error')}>
                      <TableCell><b>Gender:</b></TableCell>
                      <TableCell align="left">{userData ? userData.Constants.gender : <Skeleton  animation="wave"  variant="text" width={40} sx={{ fontSize: '1rem' }} />}</TableCell>
                    </TableRow>
                    <TableRow  onClick={handleConstants('Birth Date can\'t be changed!','error')}>
                      <TableCell><b>Date of Birth:</b></TableCell>
                      <TableCell align="left">{userData ? new Date(userData.Constants.DOB).toLocaleDateString('IST', { day: '2-digit', month: 'short',  year: 'numeric' }) : <Skeleton  animation="wave"  variant="text" width={60} sx={{ fontSize: '1rem' }} />}</TableCell>
                    </TableRow>
                    {/* {Object.entries(userData.Constants).map(([key, value]) => (
                        <TableRow  key={key} onClick={handleConstants('Birth Date can\'t be changed!','error')}>
                        <TableCell><b>{key}:</b></TableCell>
                        <TableCell align="left">{userData ? value : <Skeleton  animation="wave"  variant="text" width={60} sx={{ fontSize: '1rem' }} />}</TableCell>
                        </TableRow>
                      ))}  */}

                </TableBody>
            </Table>
          </TableContainer>

<Grid container direction="column" justifyContent="center" alignItems="center">
     <Button style={buttonStyle} onClick={handleEditProfile}>Edit Profile</Button>
     {
  userData ? userData.StageState.Faces === true : false ?
   <Button style={buttonStyle} onClick={() => { window.location.href="/ProfilePreview" }}>Go to Profile Preview</Button>
     : <></>
 }
    {/* <Button style={buttonStyle} onClick={() => { window.location.href="/ProfilePreview" }}>Go to Profile Preview</Button> */}
</Grid>
    <Snackbar open={open} autoHideDuration={2000}  onClose={handleClose}>
      <Alert  onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
        </Box>
  );
}

export default MyProfile;
