import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import {Card, Grid, Box, CardContent, Typography, Button, Divider,
        Rating, CircularProgress, Stack } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { green } from '@mui/material/colors';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import SmoothScroll from 'smooth-scroll';
function Conscientiousness() {
  const [userData, setUserData] = useState(null);
  const [phase, setPhase] = useState(0);
  const [AQ] = useState([ {
    "question": "I invest little effort into my work.",
    "reverse": false,
    "group":'A'
   },
   {
    "question": "I do not work as hard as the majority of people around me.",
    "reverse": false,
    "group":'A'
   },
   {
    "question": "Getting average grades is enough for me.",
    "reverse": false,
    "group":'A'
   },
   {
    "question": "Setting goals and achieving them is not very important to me.",
    "reverse": false,
    "group":'A'
   },
   {
    "question": "I do what is required, but rarely anything more.",
    "reverse": false,
    "group":'A'
   },
   {
    "question": "I make every effort to do more than what is expected of me.",
    "reverse": true,
    "group":'A'
   },
   {
    "question": "I have high standards and work toward them.",
    "reverse": true,
    "group":'A'
   },
   {
    "question": "I try to be the best at anything I do.",
    "reverse": true,
    "group":'A'
   },
   {
    "question": "I go above and beyond of what is required.",
    "reverse": true,
    "group":'A'
   },
   {
    "question": "I demand the highest quality in everything I do.",
    "reverse": true,
    "group":'A'
   }]);
  const [BQ] = useState([{
    "question": "Half of the time I do not put things in their proper place.",
    "reverse": false,
    "group":'B'
    },
    {
    "question": "Most of the time my room is in complete disarray.",
    "reverse": false,
    "group":'B'
    },
    {
    "question": "Being neat is not exactly my strength.",
    "reverse": false,
    "group":'B'
    },
    {
    "question": "For me, being organized is unimportant.",
    "reverse": false,
    "group":'B'
    },
    {
    "question": "I frequently forget to put things back in their proper place.",
    "reverse": false,
    "group":'B'
    },
    {
    "question": "Organization is a key component of most things I do.",
    "reverse": true,
    "group":'B'
    },
    {
    "question": "Every item in my room and on my desk has its own designated place.",
    "reverse": true,
    "group":'B'
    },
    {
    "question": "I need a neat environment in order to work well.",
    "reverse": true,
    "group":'B'
    },
    {
    "question": "I become annoyed when things around me are disorganized.",
    "reverse": true,
    "group":'B'
    }]);
  const [CQ] = useState([
    {
     "question": "I am usually not the most responsible group member, but I will not shirk on my duties either.",
     "reverse": false,
     "group":'C'
    },
    {
     "question": "I have a reputation for being late for almost every meeting or event.",
     "reverse": false,
     "group":'C'
    },
    {
     "question": "Sometimes it is too much of a bother to do exactly what is promised.",
     "reverse": false,
     "group":'C'
    },
    {
     "question": "If I am running late to an appointment, I may decide not to go at all.",
     "reverse": false,
     "group":'C'
    },
    {
     "question": "When working with others I am the one who makes sure that rules are observed.",
     "reverse": true,
     "group":'C'
    },
    {
     "question": "It bothers me when people cheat on their taxes.",
     "reverse": true,
     "group":'C'
    },
    {
     "question": "I often feel responsible for making sure that all group project assignments are completed.",
     "reverse": true,
     "group":'C'
    },
    {
     "question": "I carry out my obligations to the best of my ability.",
     "reverse": true,
     "group":'C'
    },
    {
     "question": "If I am running late, I try to call ahead to notify those who are waiting for me.",
     "reverse": true,
     "group":'C'
    },
    {
     "question": "I go out of my way to keep my promises.",
     "reverse": true,
     "group":'C'
    }]);

  const [DQ] = useState([ {
    "question": "When I was in school, I used to break rules quite regularly.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "I am known to make quick, hot-headed decisions.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "My friends say I am unpredictable.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "I am easily talked into doing silly things.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "I often rush into action without thinking about potential consequences.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "I get into trouble because I act on impulses rather than on thoughts.",
    "reverse": false,
    "group":'D'
    },
    {
    "question": "Even under time pressure, I would rather take my time to think about my answer than to say the first thing that comes to mind.",
    "reverse": true,
    "group":'D'
    },
    {
    "question": "I rarely jump into something without first thinking about it.",
    "reverse": true,
    "group":'D'
    },
    {
    "question": "I do not take unnecessary risks.",
    "reverse": true,
    "group":'D'
    },
    {
    "question": "I am careful with what I say to others.",
    "reverse": true,
    "group":'D'
    },
    {
    "question": "I dislike being around impulsive people.",
    "reverse": true,
    "group":'D'
    }]);
  const [EQ] = useState([ {
    "question": "If I could get away with it, I would not pay taxes.",
    "reverse": false,
    "group":'E'
    },
    {
    "question": "If I find money laying around, I'll keep it to myself.",
    "reverse": false,
    "group":'E'
    },
    {
    "question": "I would lie without hesitation if it served my purpose.",
    "reverse": false,
    "group":'E'
    },
    {
    "question": "I could be insincere and dishonest if the situation required me to do so.",
    "reverse": false,
    "group":'E'
    },
    {
    "question": "Even if I knew how to get around the rules without breaking them, I would not do it.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "If I accidentally scratched a parked car, I would try to find the owner to pay for the repairs.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "The people who know me best would say that I am honest.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "When I make mistakes I often blame others.",
    "reverse": false,
    "group":'E'
    },
    {
    "question": "I would rather get a bad grade than copy someone else's homework and turn it in as my own.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "I would gladly spend some of my leisure time trying to improve my community.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "If a cashier forgot to charge me for an item I would tell him/her.",
    "reverse": true,
    "group":'E'
    },
    {
    "question": "I firmly believe that under no circumstances is it okay to lie.",
    "reverse": true,
    "group":'E'
    }]);
  const [FQ] = useState([ {
    "question": "In my opinion, censorship slows down progress.",
    "reverse": false,
    "group":'F'
    },
    {
    "question": "I believe that people should be allowed to take drugs, as long as it doesn't affect others.",
    "reverse": false,
    "group":'F'
    },
    {
    "question": "People who resist authority should be severely punished.",
    "reverse": true,
    "group":'F'
    },
    {
    "question": "In my opinion, all laws should be strictly enforced.",
    "reverse": true,
    "group":'F'
    },
    {
    "question": "I have the highest respect for authorities and assist them whenever I can.",
    "reverse": true,
    "group":'F'
    },
    {
    "question": "I support long-established rules and traditions.",
    "reverse": true,
    "group":'F'
    },
    {
    "question": "People respect authority more than they should.",
    "reverse": false,
    "group":'F'
    },
    {
    "question": "I hate when people are sloppy.",
    "reverse": true,
    "group":'F'
    }]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [values, setValues] = useState([]);
  const playAudio = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/testFeedback.mp3');
    audio.volume = 0.3; // Set volume to 50%
    audio.play();
   }
   const playSubmit = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/submit.mp3');
    audio.volume = 0.5; // Set volume to 50%
    audio.play();
    audio.addEventListener('ended', () => {
      scoot(1);
    });
   }
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 3700, // Integer. Amount of time in milliseconds it should take to scroll 1000px
    easing: 'easeInOutCubic', // Easing pattern to use
   });

  const handleChange = (index) => (event, newValue) => {
    setValues(prevValues => {
      const newValues = [...prevValues];
      if(!shuffledQuestions[index].reverse){
        if(newValue === 1) newValues[index]=5;
        if(newValue === 2) newValues[index]=4;
        if(newValue === 3) newValues[index]=3;
        if(newValue === 4) newValues[index]=2;
        if(newValue === 5) newValues[index]=1;
      } else{
        if(newValue === 1) newValues[index]=1;
        if(newValue === 2) newValues[index]=2;
        if(newValue === 3) newValues[index]=3;
        if(newValue === 4) newValues[index]=4;
        if(newValue === 5) newValues[index]=5;
      } return newValues;
    });
  };
  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
    const decoded = jwt_decode(userToken);
    const FetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        if (response.data && response.data.FirstBoot === false) {
          window.location.href='/MatchStackPage';
        } setUserData(response.data);
      }
      catch (error) { console.error(error); }
    }; FetchData(); 
  }, []); 

  function shuffleArray(array) {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }return array;
  }

  const suffl = (arr)=>{
    arr.sort(() => 0.5 - Math.random());
    let box = [];
    let rev=1, pov=1;
    arr.forEach(Q => {
      if(Q.reverse && rev<=3){
        box = [...box, Q];
        ++rev;
      }
      if(!Q.reverse && pov<=3){
        box = [...box, Q];
        ++pov;
      }
    });
    return box;
  }
  const sufflF = (arr)=>{
    arr.sort(() => 0.5 - Math.random());
    let box = [];
    let rev=1, pov=1;
    arr.forEach(Q => {
      if(Q.reverse && rev<=2){
        box = [...box, Q];
        ++rev;
      }
      if(!Q.reverse && pov<=2){
        box = [...box, Q];
        ++pov;
      }
    });
    return box;
  }
  
useEffect(() => {
  let AQmix = suffl(AQ);
  let BQmix = suffl(BQ);
  let CQmix = suffl(CQ);
  let DQmix = suffl(DQ);
  let EQmix = suffl(EQ);
  let FQmix= sufflF(FQ);
  let megamix=[...AQmix, ...BQmix, ...CQmix, ...DQmix, ...EQmix, ...FQmix]
   setShuffledQuestions(shuffleArray(megamix));
}, []);


  const init = async ()=>{
    setPhase(1);
  }

  const scoot = async (i) =>{
  

   let G1 = values.filter((_, index) => shuffledQuestions[index]).map((respons) => respons);

   let G2 = values.filter((_, index) => shuffledQuestions[index].group !=='F')
   .map((respons) => respons);

   let doner=true;
   
  const G1score = G1.reduce((accumulator, currentValue, index) => {
    if(currentValue===undefined){doner=false}
      return accumulator + currentValue;
    }, 0);
    
    const G2score = G2.reduce((accumulator, currentValue, index) => {
      return accumulator + currentValue;
    }, 0);
    
    if(doner===true){
  
  const result ="G1 Score:"+G1score+" | G2score:"+G2score;
  
  // setPhase(7)
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = {
        id: userData._id,
        score: result,
        test:"CO"
      };
      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/update`, body, config );
      window.location.href='/State';

    } catch (err) {
      alert('Internal Error Occured!')
      window.location.href="/"
    }
  }else{
    alert("Please answer all questions")
  }
  }

  const buttonStyle={
    display: 'flex', width: '343px', padding: '16px 32px', marginTop: '3vh',
    flexDirection: 'column', alignItems: 'center', gap: '12px',
    borderRadius: '100px', backgroundColor: '#D8234C', color:'white'
  }
  
  // const isAuthenticated = !!localStorage.getItem('userToken');

 return(
<div>
{ phase===0 ? // Declaration

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '70vh' }} >
    <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            <FavoriteIcon /> Conscientiousness <br/>
          </Typography>
          <Typography gutterBottom variant="body1" align="center" component="div">
          The questionnaire is based on the scales developed by personality psychologist <span style={{color:'#D8234C'}}>Dr. John A. Johnson</span>, Professor Emeritus of
Psychology, Penn State University, and resources made available by <span style={{color:'#D8234C'}}>Dr Chris Fraley</span>, Professor at the University of Illinois's Department of Psychology website for
educational purpose.
          <br/><br/>
          Both Dr Johnson and Dr Fraley have done pathbreaking work in on the subject. 
          <br/><br/>
          <span style={{color:'#D8234C'}}> Duration: 5-7 mins</span><br/><br/>
          <Button onClick={() => init()}>Start</Button>
          </Typography>
      </CardContent>
    </Card>
</Grid>

: phase===1 ? // Page 1

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '20vh' }} >
  <Box sx={{ '& > legend': { mt: 2 },}} component='div' style={{ margin:'10px' }}>
    <Typography variant="h5" component="div" sx={{ flexGrow: 1}} style={{ textAlign: 'center' }} >
       <b> Conscientiousness </b>
    </Typography>
    <Typography align='left' style={{color:'grey', margin:'5px'}}>
    Please select the answer that best
represents your view. Answer ALL
questions.</Typography><hr/>
{shuffledQuestions !=null ? ( <>
    <Stack  spacing={2} divider={<Divider flexItem />}>
    {shuffledQuestions.map((Question, index) => (
  <div style={{ padding: '20px' }} key={index}>
      <Typography key={`Q${index}`} component="legend">{Question.question}</Typography>
      <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px'}} >
          <Rating key={`rating${index}`} name={`rating${index}`} value={values[index] || undefined} max={5}
                style={{ color: '#D8234C', fontSize: '2.4em' }} icon={<CircleIcon fontSize="inherit" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />} onChange={handleChange(index)}
                onClick={() => {
                  scroll.animateScroll(window.scrollY + 190);
                  // playAudio();
                 }}
                highlightSelectedOnly />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2 }}>
          <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Highly Inaccurate</Typography>
          <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Highly Accurate</Typography>
      </Box>
  </div>
))}
</Stack>
<Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px', marginBottom:'40px'}} >
<Button style={buttonStyle} onClick={()=>{playSubmit();}}> Submit </Button>
</Box>
</> ) : (<Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}> <CircularProgress /> </Box> )}
</Box>
<Divider/>
</Grid>

: phase===5 ?
(
  <Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}>
      <CircularProgress />
  </Box>
)
:
<Grid
container
justifyContent="center"
alignItems="center"
style={{ minHeight: '70vh' }}
>
<Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
<CardContent>
<Typography gutterBottom variant="h5" align="center" component="div">
<FavoriteIcon sx={{color: green[500]}} /> <b>Conscientiousness</b>
</Typography>
<Typography gutterBottom variant="h6" align="left" component="div">
    Splendid! Let's proceed to the next one.
    <br/>
You can return to it anytime. Once you login, the app will bring you here directly.

    </Typography>
    <Button component={Link} to={'/State'}> Next</Button>
</CardContent>
</Card>
</Grid>
}
</div>
 )
}
export default Conscientiousness;
