import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Boot from './components/Boot';
import Home from './components/Home';
import Navo from './components/Navo';
import MobNav from './components/MobNav';
import MatchStackPage from './components/MatchStackPage';
import MyProfile from './components/MyProfile';
import CompleteProfile from './components/CompleteProfile';
import PIT from './components/PIT';
import Biometric from './components/Biometric';
import Settings from './components/Settings';
import MatchBox from './components/MatchBox';
import EMeets from './components/E-Meets';
import State from './components/Test_PM/State';
import AttachmentStyle from './components/Test_PM/AttachmentStyle';
import LifeSatisfaction from './components/Test_PM/LifeSatisfaction';
import Conscientiousness from './components/Test_PM/Conscientiousness';
import Mindset from './components/Test_PM/Mindset';
import Paywall from './components/Paywall';
import PP from './components/PP';
import EULA from './components/EULA';
import WaitList  from './components/WaitList';
import FeedBack from './components/FeedBack';
import ProfilePreview from './components/ProfilePreview';
import PasswordReset from './components/PasswordReset';
import Share from './components/Share';

function App() {
  const isAuthenticated = !!localStorage.getItem('userToken');
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
  
    const decoded = jwt_decode(userToken);
    const FetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    FetchData();
  }, []); 

let isBooted = userData && userData.StageState.FirstBoot; // state
let isExam= userData && userData.StageState.Exam; // Payment
let isMoney= true; // CompleteProfile
let isProfile= userData && userData.StageState.Profile; // PIT
let isPITs= userData && userData.StageState.PITs; // Biometric
let isFaces= userData && userData.StageState.Faces; // WaitList

  return (
    <BrowserRouter>
      { isAuthenticated && <Navo /> }
      <Routes>

{/* Boot Screen */}
        <Route path="/boot" element={ isAuthenticated ? ( <Boot /> ) : ( <Navigate to="/" replace /> )}/>

{/* Login and Register */}
        <Route path="/register" element={isAuthenticated ? ( <Boot /> ) : ( <Register /> )} />
        <Route path="/PrivacyPolicy" element={<PP />} />
        <Route path="/TermsOfUse" element={<EULA />} />
        <Route path="/Share" element={<Share />} />
        <Route path="/" element={ isAuthenticated ? isFaces ? ( <Navigate to="/WaitList" replace /> ) : ( <Boot /> ) : ( <Home/> )}/>
        <Route path="/login" element={isAuthenticated ? ( <Boot /> ) : ( <Login /> )} />
        <Route path="/PasswordReset" element={isAuthenticated ? ( <Boot /> ) : ( <PasswordReset /> )} />

{/* Internal App Routes */}
        <Route path="/MatchStackPage" element={ isAuthenticated ? ( <MatchStackPage /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/Settings" element={ isAuthenticated ? ( <Settings /> ) : ( <Navigate to="/" replace /> )} />
        <Route path="/MyProfile" element={ isAuthenticated ? isExam ? ( <Navigate to="/state" replace /> ) : ( <MyProfile /> ) : ( <Home/> )}/>
        <Route path="/CompleteProfile" element={ isAuthenticated ? ( <CompleteProfile /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/PIT" element={ isAuthenticated ? ( <PIT /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/Biometric" element={ isAuthenticated ? ( <Biometric /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/MatchBox" element={ isAuthenticated ? ( <MatchBox /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/EMeets" element={ isAuthenticated ? ( <EMeets /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/Payment" element={ isAuthenticated ? ( <Paywall /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/WaitList" element={ isAuthenticated ? ( <WaitList /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/FeedBack" element={ isAuthenticated ? ( <FeedBack /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/ProfilePreview" element={ isAuthenticated ? ( <ProfilePreview /> ) : ( <Navigate to="/" replace /> )}/>


{/* Test HAll */}
        <Route path="/State" element={ isAuthenticated ? ( <State /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/State/AS" element={ isAuthenticated ? ( <AttachmentStyle /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/State/LS" element={ isAuthenticated ? ( <LifeSatisfaction /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/State/CO" element={ isAuthenticated ? ( <Conscientiousness /> ) : ( <Navigate to="/" replace /> )}/>
        <Route path="/State/MS" element={ isAuthenticated ? ( <Mindset /> ) : ( <Navigate to="/" replace /> )}/>

{/* fall back route */}
<Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      { isAuthenticated && isFaces && <MobNav /> }
    </BrowserRouter>
  );
}

export default App;
