import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {Card, Grid, Box,
        CardActions, CardContent, CardMedia, Typography, CircularProgress, Button,
        List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { green, grey } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import PsychologyIcon from '@mui/icons-material/Psychology';

import { Link } from 'react-router-dom';

function State() {
  let progress=10;
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
  
    const decoded = jwt_decode(userToken);
    const FetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        if (response.data && response.data.StageState.Exams === true) {
          window.location.href='/CompleteProfile';
        }
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    FetchData();
  }, []); 

  const RuleMaker = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let body = {
        id: userData._id,
        Exams:true
      };
      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
      window.location.href='/CompleteProfile';

    } catch (err) {
      alert('Internal Error Occured!')
      window.location.href="/"
    }};
   


  if(userData && userData.PMT.LifeSatisfaction.Status === true){progress += 15}
  if(userData && userData.PMT.AttachmentStyle.Status === true){progress += 15}
  if(userData && userData.PMT.Conscientiousness.Status === true){progress += 15}
  if(userData && userData.PMT.Mindset.Status === true){progress += 15}
  let allTrue = false;
  if(userData && userData.PMT) {
    allTrue = Object.values(userData.PMT).every(item => item.Status === true);
  }
  

  // const isAuthenticated = !!localStorage.getItem('userToken');
  const buttonStyle={
    display: 'flex',
    width: '343px',
    height: '51px',
    justifyContent: 'center',
    marginTop:'20px',
    marginBottom:'20px',
    borderRadius:'30px',
    alignItems: 'center',
    flexShrink: '0',
    backgroundColor:'#D8234C',
    color:'white'
  }
 return(
<div>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '90vh' }}
    >
      <Box>
    <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <CardMedia component="img" alt="MatchWorthee" height="90" image="./logoP.png" sx={{ objectFit: 'contain' }} />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom:'20px'}}>
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" size='5rem' thickness={1} value={100} sx={{ color: '#dcdcdc' }} />
                <CircularProgress variant="determinate" size='5rem' thickness={1} value={progress}  sx={{color:'#D8234C', position: 'absolute' }} />
                <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center',}} >
                  <Typography variant="h6" component="div" color="text.secondary">{progress}%</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>  
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
            <Typography variant="body2" style={{color:'grey'}}>
             {progress===10 ?
                <span>
                  There are 4-sets of questionnaires below. Click on each section to share your responses. 
<br/><br/>
Please be honest. <span style={{"color":'black'}}>It’s critical to help us understand the real you.</span> Only then would we be able to suggest a deeply compatible match.😊
<br/><br/>
Your responses are to help us understand you better and shall not be shared with any other user or 3rd parties. 
<br/><br/>
It would take between 15-22 minutes to complete all 4 questionnaires. 
<br/><br/>
                </span>
              :progress===25 ?
                <span>Great!<br/>
                Mindset helps us understand your views about our ability to influence basic qualities like intelligence, talent, etc. 
                <br/><br/>
                Please proceed to the next questionnaire – Conscientiousness. 
                </span>

                :progress===40 ? 
              <span>Splendid!<br/>
 
              Conscientiousness summarizes our commitment, integrity, industriousness, and dependability.  
              <br/><br/>
              Please proceed to the next questionnaire – Satisfaction with life. 
              </span>

              :progress===55 ?
              <span>Excellent!<br/> 

              Satisfaction with life indicates how we feel about the way our life has progressed so far and our future expectations from it. 
              <br/><br/>
              Please proceed to the last questionnaire – Attachment Style. 
              </span>

              :progress===70 ?
              <span>Fantastic!<br/> 

              You have answered 95 questions covering your four key character traits: Mindset, Conscientiousness, Satisfaction with Life, and Attachment Style. 
              <br/><br/>
              You may please proceed to the next step. 
              </span>

              :<span>There are 4-sets of questionnaires below. Click on each section to answer questions. Honestly.<br/>
              Else, we won’t be able to find you a deeply compatible companion.</span>
              }
            </Typography>
          </Box>
          </CardContent>
      <CardActions>
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List>
      <ListItem disablePadding>
            <ListItemButton component={userData && !userData.PMT.Mindset.Status ?Link : ''} to={'/State/MS'}>
              <ListItemIcon>
                <PsychologyIcon />
              </ListItemIcon>
              <ListItemText primary="Mindset" />
              {userData && userData.PMT.Mindset.Status === true && ( <CheckCircleIcon edge="end"  sx={{ color: green[500] }} /> )}
              {userData && userData.PMT.Mindset.Status === false && (<CheckCircleOutlineIcon edge="end"  sx={{ color: grey[500] }} /> )}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  component={userData && !userData.PMT.Conscientiousness.Status ?Link : ''} to={'/State/CO'}>
              <ListItemIcon>
                <SelfImprovementIcon />
              </ListItemIcon>
              <ListItemText primary="Conscientiousness" />
              {userData && userData.PMT.Conscientiousness.Status === true && ( <CheckCircleIcon edge="end"  sx={{ color: green[500] }} /> )}
              {userData && userData.PMT.Conscientiousness.Status === false && (<CheckCircleOutlineIcon edge="end"  sx={{ color: grey[500] }} /> )}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  component={userData && !userData.PMT.LifeSatisfaction.Status ?Link : ''} to={'/State/LS'}>
              <ListItemIcon>
                <SentimentVerySatisfiedIcon />
              </ListItemIcon>
              <ListItemText primary="Satisfaction with Life" />
              {userData && userData.PMT.LifeSatisfaction.Status === true && ( <CheckCircleIcon edge="end"  sx={{ color: green[500] }} /> )}
              {userData && userData.PMT.LifeSatisfaction.Status === false && (<CheckCircleOutlineIcon edge="end"  sx={{ color: grey[500] }} /> )}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding >
            <ListItemButton  component={userData && !userData.PMT.AttachmentStyle.Status ?Link : ''}  to={'/State/AS'}>
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary="Attachment Style" />
              {userData && userData.PMT.AttachmentStyle.Status === true && ( <CheckCircleIcon edge="end"  sx={{ color: green[500] }} /> )}
              {userData && userData.PMT.AttachmentStyle.Status === false && (<CheckCircleOutlineIcon edge="end"  sx={{ color: grey[500] }} /> )}
            </ListItemButton>
          </ListItem>
        </List>
        </Box>
      </CardActions>
      {userData && userData.PMT.AttachmentStyle.Status === true && userData.PMT.LifeSatisfaction.Status === true && userData.PMT.Conscientiousness.Status === true && userData.PMT.Mindset.Status === true && (<Button style={buttonStyle} onClick={allTrue && (() => RuleMaker())}>PROCEED T0 STEP 2</Button>)}
    </Card>
    </Box>
    </Grid>
    </div>
 )
}

export default State;
