import React, { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import {Button} from '@mui/material';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as faceapi from 'face-api.js';

const inputResolution = {
 width: 300,
 height: 300,
};

const videoConstraints = {
 width: inputResolution.width,
 height: inputResolution.height,
 facingMode: "user",
};

function Biometric() {
 const [userData, setUserData] = useState(null);
 const decoded = jwt_decode(localStorage.getItem('userToken'));
 const [faceDetected, setFaceDetected] = useState(false);

 useEffect(() => {
    const fetchData = async () => { try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
      if (response.data && response.data.StageState.Faces === true) {
        window.location.href='/WaitList';
      }
      setUserData(response.data);
      } catch (error){ console.error(error); } }; fetchData(); }, [decoded.id]);

 const webcamRef = useRef(null);
 const [imgSrc, setImgSrc] = useState(null);
 let imageUrl;
 
// const capture = useCallback(
//   async () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setImgSrc(imageSrc);
//     imageUrl = imageSrc
//     console.log(imageUrl)
//     setCaptured(true);
//   },
//   [webcamRef]
//  );

//  const save = useCallback(
//   async () => {
 
//   //  const imageUrl = imgSrc.toDataURL(); // imageSrc is already a base64 encoded image
 
//    try {
//      const config = { headers: { 'Content-Type': 'application/json', }, };
//      const body = { id: userData._id, face: imageUrl };
//      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config);
//      if(res.status === 200){ 
//        console.log("success");
//        window.location.href = "/WaitList";
//      }
//    } catch (err) { 
//      console.log(err.response.data); 
//    }
//   },
//   [webcamRef, userData]
//  );

const capture = useCallback(
  async () => {

  const imageSrc = webcamRef.current.getScreenshot();
   setImgSrc(imageSrc);
 
   const imageUrl = imageSrc;
   try {
     const config = { headers: { 'Content-Type': 'application/json', }, };
     const body = { id: userData._id, face: imageUrl };
     const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config);
     if(res.status === 200){ 

       setCaptured(true);
      
     }
   } catch (err) { 
    alert('Internal Error Occured!')
    window.location.href="/"
   }
  },
  [webcamRef, userData]
 );
 
 const save = (
  async () => {
    try {
      const config = { headers: { 'Content-Type': 'application/json', }, };
      const body = { id: userData._id,'Faces':true  };
      await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
      window.location.href = "/WaitList";
    } catch (err) {
      alert('Internal Error Occured!')
      window.location.href="/" }
  }
 );
 

//  useEffect(() => {
//   const loadModels = async () => {
//     const MODEL_URL = process.env.PUBLIC_URL + '/models';
//     await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
//     await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
//     await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
  
//   // await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
//   // await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
//   // await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
// };

//   loadModels();
// }, []);
// const [detectInterval, setDetectInterval] = useState(null);

// useEffect(() => {
//  return () => {
//    if (detectInterval) {
//      clearInterval(detectInterval);
//    }
//  };
// }, [detectInterval]);

const detectFaces = async () => {
  if (webcamRef.current) {
   const imageSrc = webcamRef.current.getScreenshot();
   const response = await fetch(imageSrc);
   const blob = await response.blob();
   const image = await faceapi.bufferToImage(blob);
   const detections = await faceapi.detectAllFaces(image);
   if (detections.length > 0) {
     setFaceDetected(true);
   } else {
     setFaceDetected(false);
   }
  }
 };
 
 const buttonStyle={
 display: 'flex', width: '343px', height: '51px', justifyContent: 'center', marginTop:'400px',
 marginBottom:'20px', borderRadius:'30px', alignItems: 'center', flexShrink: '0', backgroundColor:'#D8234C', color:'white' }
 
 const retakeButtonStyle = {
  backgroundColor: '#D8234C',
  borderRadius: '80px',
  color: 'white',
  opacity: 1,
 };
 const [captured, setCaptured] = useState(false);

 const retake = () => {
  setCaptured(false);
  setImgSrc(null);
 };
 

  return (   
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  {captured ? (
    <>
      <img src={imgSrc} style={{ position: "absolute", borderRadius: "50%", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} alt="webcam" />
      <Button onClick={retake}  style={retakeButtonStyle} > Retake </Button>
    
    </>
  ) : (<>
    <Webcam
      style={{ position: "absolute", borderRadius: "50%", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      videoConstraints={videoConstraints}
      ref={webcamRef}
      mirrored={true}
         //  onCanPlay={() => {
      //    detectFaces();
      //    setDetectInterval(setInterval(detectFaces, 2000));
      //  }}

    />
  </>
  )}
</div>
{!captured && (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    <Button style={buttonStyle} onClick={capture}>Capture photo</Button>
  </div>
)}
  {captured && (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    <Button style={buttonStyle} onClick={save}>Save photo</Button>
  </div>
)}
</div>
   );
   
}

export default Biometric;
