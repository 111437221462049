import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import {Card, Grid, Box, CardContent, Typography, Button, Divider,
        Rating, CircularProgress, Stack } from '@mui/material';
import { green } from '@mui/material/colors';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import SmoothScroll from 'smooth-scroll';



function Mindset() {
  const [userData, setUserData] = useState(null);
  const [phase, setPhase] = useState(0);
  const [values, setValues] = useState([]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [Questions, setQuestions] = useState([
    {
     "question": "Your intelligence is something very basic about you that you can’t change very much.",
     "reverse": false
    },
    {
     "question": "No matter how much intelligence you have, you can always change it quite a bit.",
     "reverse": true
    },
    {
     "question": "You can always substantially change how intelligent you are",
     "reverse": true
    },
    {
     "question": "You are a certain kind of person, and there is not much that can be done to really change that.",
     "reverse": false
    },
    {
     "question": "You can always change basic things about the kind of person are you",
     "reverse": true
    },
    {
     "question": "Music talent can be learned by anyone.",
     "reverse": true
    },
    {
     "question": "Only a few people will be truly good at sports – you have to be “born with it.”",
     "reverse": false
    },
    {
     "question": "Math is much easier to learn if you are male or maybe come from a culture that values math.",
     "reverse": false
    },
    {
     "question": "The harder you work at something, the better you will be at it",
     "reverse": true
    },
    {
     "question": "No matter what kind of person you are, you can always change substantially.",
     "reverse": true
    },
    {
     "question": "Trying new things is stressful for me and I avoid it.",
     "reverse": false
    },
    {
     "question": "Some people are good and kind, some are not – it is not often that people change.",
     "reverse": false
    },
    {
     "question": "I appreciate when parents, coaches, teachers give me feedback about my performance.",
     "reverse": true
    },
    {
     "question": "I often get angry when I get feedback about my performance",
     "reverse": false
    },
    {
     "question": "All human beings without a brain injury or birth defect are capable of the same amount of learning.",
     "reverse": true
    },
    {
     "question": "You can learn new things, but you can’t really change how intelligent you are.",
     "reverse": false
    },
    {
     "question": "You can do things differently, but the important part of who you are can’t really be changed.",
     "reverse": false
    },
    {
     "question": "Human beings are basically good, but sometimes make terrible decisions.",
     "reverse": true
    },
    {
     "question": "An important reason why I do my schoolwork is that I like to learn new things.",
     "reverse": false
    },
    {
     "question": "Truly smart people don’t need to try hard",
     "reverse": false
    }
   ]
   );
   const playAudio = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/testFeedback.mp3');
    audio.volume = 0.3; // Set volume to 50%
    audio.play();
   }
   const playSubmit = () => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/submit.mp3');
    audio.volume = 0.5; // Set volume to 50%
    audio.play();
    audio.addEventListener('ended', () => {
      scoot(1);
    });
   }
  const handleChange = (index) => (event, newValue) => {
    setValues(prevValues => {
      const newValues = [...prevValues];
      if(Questions[index].reverse){
        if(newValue === 4) newValues[index]=3;
        if(newValue === 3) newValues[index]=2;
        if(newValue === 2) newValues[index]=1;
        if(newValue === 1) newValues[index]=0;
      } else{
        newValues[index] = newValue-1;
      } return newValues;
    });
  };

  function shuffleArray(array) {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
  
    return array;
  }
  
useEffect(() => {
   setShuffledQuestions(shuffleArray(Questions));
}, []);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) return;
  
    const decoded = jwt_decode(userToken);
    const FetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
        if (response.data && response.data.FirstBoot === false) {
          window.location.href='/MatchStackPage';
        }
        setUserData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    FetchData();
  }, []); 


  const init = async ()=>{
    setPhase(1);
    }

    var scroll = new SmoothScroll('a[href*="#"]', {
      speed: 3700,
      easing: 'easeInOutCubic',
     });

    const scoot = async (i) =>{
      let doner=true;
      const score = values.reduce((accumulator, currentValue, index) => {
        if(currentValue===undefined){doner=false}
        return accumulator + currentValue;
      }, 0);
      if(doner===true){
  
      const result = score>=0 && score<=20 ? "Strong Fixed Mindset | score:"+score:
                      score>21 && score<=33 ? "Fixed Mindset with some Growth ideas | score:"+score:
                      score>34 && score<=44 ? "Growth Mindset with some Fixed ideas | score:"+score:
                      score>45 && score<=60 ? "Strong Growth Mindset | score:"+score: "INVALID RESULT | score:"+score
      // setPhase(7)
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        const body = {
          id: userData._id,
          score: result,
          test:"MS"
        };
        const res = await axios.post(`${process.env.REACT_APP_SERVER}user/update`, body, config );
        window.location.href='/State';

      } catch (err) {
        alert('Internal Error Occured!')
        window.location.href="/"
      }
    }else{
      alert("Please answer all questions")
    }
    }

  const buttonStyle={
    display: 'flex', width: '343px', padding: '16px 32px', marginTop: '3vh',
    flexDirection: 'column', alignItems: 'center', gap: '12px',
    borderRadius: '100px', backgroundColor: '#D8234C', color:'white'
  }
  // const isAuthenticated = !!localStorage.getItem('userToken');

 return(
<div>
{ phase===0 ? // Declaration

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }} >
    <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            <SentimentVerySatisfiedIcon /> Mindset
            </Typography>
          <Typography gutterBottom variant="body1" align="center" component="div" style={{color:'grey'}}>
          This questionnaire is adapted from Carol Dweck's book <span style={{color:'#D8234C'}}>Mindset: The new psychology of success.</span> (Dweck, 2006).
          <br/><br/>
Carol Susan Dweck is an American psychologist. She holds the Lewis and Virginia Eaton Professorship of Psychology at Stanford University. Dweck is known for her work on motivation and mindset.
          <br/><br/>
          <span style={{color:'#D8234C'}}> Duration: 3-5 mins</span><br/><br/>
          <Button align="center" onClick={() => init()}>Start</Button>
          </Typography>
      </CardContent>
    </Card>
</Grid>

: phase===1 ? // Page 1

<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '20vh' }} >
  <Box sx={{ '& > legend': { mt: 2 },}} component='div' style={{ margin:'10px' }}>
    <Typography variant="h5" component="div" sx={{ flexGrow: 1}} style={{ textAlign: 'center' }} >
       <b> Mindset </b>
    </Typography>
       <Typography align='left' style={{ margin:'5px'}}>Please select the answer that best represents your view. Answer ALL questions.</Typography>
       <hr/>
{Questions !=null ? ( <>
    <Stack  spacing={2} divider={<Divider flexItem />}>
    {shuffledQuestions.map((Question, index) => (
  <div style={{ padding: '20px' }} key={index}>
      <Typography key={`Q${index}`} component="legend">{Question.question}</Typography>
      <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px'}} >
          <Rating key={`rating${index}`} name={`rating${index}`} value={values[index] || undefined} max={4}
                style={{ color: '#D8234C', fontSize: '2.4em'}} icon={<CircleIcon fontSize="inherit" />}
                emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />} onChange={handleChange(index)} 
                onClick={() => {
                  scroll.animateScroll(window.scrollY + 190);
                  // playAudio();
                 }}
                highlightSelectedOnly />



      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2 }}>
          <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Disagree</Typography>
          <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Agree</Typography>
      </Box>
  </div>
))}
</Stack>
<Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px', marginBottom:'40px'}} >
<Button style={buttonStyle}onClick={()=>{playSubmit();}}> Submit </Button>
</Box>
</> ) : (<Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}> <CircularProgress /> </Box> )}
</Box>
<Divider/>
</Grid>

: phase===5 ?
(
  <Box  display="flex" justifyContent="center"  alignItems="center"  style={{ height: '70vh' }}>
      <CircularProgress />
    </Box>
)
:
<Grid
container
justifyContent="center"
alignItems="center"
style={{ minHeight: '70vh' }}
>
<Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
<CardContent>
<Typography gutterBottom variant="h5" align="center" component="div">
<SentimentVerySatisfiedIcon  sx={{color: green[500]}} /> <b>Mindset</b>
</Typography>
    <Typography gutterBottom variant="h6" align="left" component="div">
    {/* Great! You are done. Proceed to the next one.
<br/><br/>
Or you can return to it anytime. Once you login, the app will bring you here directly. */}
Great!<br/><br/>You have finished with this questionnaire. Proceed to the next one please
    </Typography>
    <Button varient="h6" component={Link} to={'/State'}> Next</Button>
</CardContent>
</Card>
</Grid>
} 
</div>
 )
}

export default Mindset;
