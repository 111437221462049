import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, Card, CardMedia, CardContent } from '@mui/material';


function PP() {
 return(
    <Box sx={{ m: 3, p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
   <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
     <CardMedia
       component="img"
       alt="app.MatchWorthee.com"
       height="100"
       image="./logoP.png"
     />
     <CardContent align="center">
       <Typography align="center" gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/Register'}>
       Register
     </Typography>
     </CardContent>
   </Card>
 </Box>
    <Typography variant="h2"  sx={{ mt: 2, mb: 2 }} align="center">Privacy Policy</Typography>
    <Typography variant="body1" align="justify">
    Matchworthee is strongly committed to your right to privacy. This section covers
Matchworthee&#39;s privacy policy.<br/>
It explains how Matchworthee handles personally identifiable information that it collects
when you are on our website or App. Please read this privacy policy carefully. All
information covered by Matchworthee&#39;s privacy policy will continue to be protected under
Matchworthee&#39;s privacy policy irrespective of any change in your membership status in the
future. By using the Matchworthee Website or App, you accept all practices described in
this privacy policy.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Personal information</Typography>
    <Typography variant="body1" align="justify">
    In this Privacy Policy, your &quot;Personal Data&quot; means information or pieces of information that
could allow you to be identified. We process your personal data for us to be able to carry
out our contractual commitments towards you. We use this information for following
purpose:
<List sx={{ listStyleType: 'disc', pl: 4 }}>
   <ListItem sx={{ display: 'list-item' }}>
     <ListItemText primary="Process Personal Data to comply with necessary legal obligations." />
   </ListItem>
   <ListItem sx={{ display: 'list-item' }}>
     <ListItemText primary="Process your Personal Data for allowing other users to view your profile on Matchworthee." />
   </ListItem>
   <ListItem sx={{ display: 'list-item' }}>
     <ListItemText primary="Conduct an analysis of your browsing pattern and history;" />
   </ListItem>
   <ListItem sx={{ display: 'list-item' }}>
     <ListItemText primary="Allowing our access, payment and storage business partners to process your Personal Data." />
   </ListItem>
   <ListItem sx={{ display: 'list-item' }}>
     <ListItemText primary="Your responses to our psychometric tests for the purpose of improving our ability to find you compatible companion." />
   </ListItem>
 </List>
 Sometimes we need your consent to process Personal Data, such as for sending you
newsletters and promotions. Before you can become a user we also ask for your explicit
consent so that we can:<br/>
Place tracking cookies
<List sx={{ listStyleType: 'disc', pl: 4 }}>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process your profile information" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process data that you enter about your partner preferences" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process photographs of you" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process details of your work and interests" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process your bio and other textual content added to your profile" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Process your geolocation" />
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText primary="Offer you services mentioned in this Privacy Policy that require an act of consent from you" />
  </ListItem>
 </List>

 You have the right to withdraw your consent at any time for processing your location,
however, this will not affect any of the prior processing of your Personal Data.<br/>
You will be asked to register before you can use Matchworthee. In the process, you will be
asked for your name, mobile number, email ID, gender and date of birth, etc. After
registering, you would ask to undertake certain mandatory psychometric tests. The tests
used are publicly available on third party websites. Your responses will be shared with
psychometric testing companies and test results obtained from them. The results help us
identify your potential matches but no personally identifiable data will be shared with
test/quiz partners/associates/third parties (“Testing Parties”). Testing Parties would share
with Matchworthee your score across tests/quizzes you undertake and recommend your
acceptance to access Matchworthee’s services and to create your personal Matchworthee
account (&quot;Your Account&quot;).<br/>
In this respect, you are invited to provide us with your name, pictures of you, answer a few
personal questions related to your height, weight, marital status, relationship preference,
education, profession and occupation, and share your social media links, etc. Matchworthee
would use the information to suggest compatible matches.<br/>
Please note that we will need to retain your email address and phone number for
communication purposes and payment related records.<br/>
Furthermore, we will process your IP address, login information, customer service history,
time zone settings, device ID, browser language and - if you have given us explicit
permission – your geolocation.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Using your personal data</Typography>
    <Typography variant="body1" align="justify">
    We will only share parts of your personal data which is necessary to assess and match you
to another user who could be interesting for you – which will be clarified below.‍
We will only disclose your Personal Data to third parties if there is a legal obligation to do so
by a statutory authority from the appropriate jurisdiction. <b>We do not sell your Personal
Data to third parties.</b><br/><br/>
<b>Your Account data:</b> Your mobile number will be your identity on our platform. Information
tendered will be visible only to you, barring data that Matchworthee believes is necessary to
suggest compatible matches.<br/><br/>
<b>Your login information:</b> We will process and store your login information to verify your
identity to access Your Account.<br/><br/>
<b>Your email address:</b> We do not share your email address with third parties.<br/><br/>
<b>Your email address will be used to:</b> inform you of your membership status; notify you other
relevant activity regarding Your Account; engage you with the application through
newsletters and for troubleshooting.<br/><br/>
<b>SMS from Matchworthee:</b> By registering you give us permission to send you SMS
notifications. If you wish to not receive them, please mail us at team@Matchworthee.com.<br/><br/>
<b>Your geolocation:</b> We will share your current location to make it insightful for our users
who may live or work nearby. We will never share your zip code with other users of
Matchworthee. You can choose to share your geolocation, which is necessary to see which
other users of Matchworthee are nearby at any given moment. Your geolocation will only
be used to suggest approximate location of suggests matches.<br/><br/>
<b>Your date of birth:</b> Your date of birth is used to calculate your age, which will be visible on
Your Account.<br/><br/>
<b>Your height and weight:</b> Your height and weight are used to suggest compatible matches.
The data would be visible on Your Account<br/><br/>
<b>Photos you upload:</b> While creating Your Account, it is necessary to upload pictures of
yourself and to select a display picture for Your Account. We may use an external face
recognition tool and other techniques to assign you attractiveness score. This selection may
be based on features like image quality, composition and authenticity. Images uploaded by
you may be modified by us to make them more presentable on the platform. New pictures
uploaded by you will be reviewed before they appear on Your Account. The pictures that
you upload will be displayed to other users. Your Account, including pictures might be
displayed at the time of suggesting you as a match.<br/><br/>
<b>Your chat messages and other written content:</b> Your chat messages, if used, shall be strictly
confidential and is only processed in order to give you access to your chat-history. Please
note that we do use filter software and techniques that notifies us of chat messages with
inappropriate content (e.g., derogatory or aggressive language, or unlawful use of our
platform for advertisements or link-building.) After such notification we reserve the right to
erase the offensive content.<br/><br/>
<b>Your video/virtual meet recording.</b> The virtual meet will be recorded, and recording
preserved in our database for s period of 1 month. The recording will be password
protected. It will be accessible to Matchworthee only upon your approval. The recording
would be accessed by Matchworthee only at your behest in case you report any abuse you
may have experienced during a virtual meet. Matchworthee would take appropriate action
against the abusive user, which may include expulsion.<br/><br/>
<b>Your customer service history:</b> If you contact our customer service team, we keep a record
of your correspondence with us, in order to optimize our service for you.<br/><br/>
<b>Non-personal data:</b> We may also process non-personal data whenever you use our website
or app. This data may include your browser type and version, the type of your computer or
mobile device and technical information about your means to connect to our Websites
(such as your operating system and platform and the utilized internet service providers).<br/><br/>
<b>Push notifications from Matchworthee:</b> If you have given us explicit permission, we will
send you push notifications via our App to inform you about your membership status and to
notify you of new messages you have received and other activity with regard to Your 
Account. We may also send you push notifications with suggestions of people you might like
and other promotional material.<br/><br/>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Referrals</Typography>
    <Typography variant="body1" align="justify">
    If you refer anyone to Matchworthee, you will send an email message, WhatsApp message,
SMS, Messenger message to the contact details you submitted to us. We won&#39;t share these
details with anyone else. By choosing to refer anyone to join Matchworthee you confirm
that those you refer are happy to receive the invitation. You also hereby agree to indemnify
and hold Matchworthee harmless from all claims arising out of your messages to any person
through Matchworthee. You must and will not use our invite friends feature to send spam.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Protecting your personal data</Typography>
    <Typography variant="body1" align="justify">
    We try our best to ensure that your Personal Data is protected and secure from
unauthorized or unlawful access, alteration, disclosure, use or destruction. We encrypt our
services using SSL, the data is accessible only through a secret password and digital
signatures, and our employees only have access to your data on a need-to-know basis.
Unfortunately, the transmission of information via the internet is not always completely
secure. Although we take all reasonable measures, we cannot guarantee the security of the
data you transmit to our website or App; any transmission is at your own risk.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Retaining your personal data</Typography>
    <Typography variant="body1" align="justify">
    Your Personal Data is retained no longer than strictly necessary or as long as allowed by law,
whichever is shorter. In the event that you have not logged in to your profile for five years,
your Personal Data may be erased from our records within 7 days. Of course, you can
always contact our support team through your registered email address with a request to
delete your data from our records.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Changes to this privacy policy</Typography>
    <Typography variant="body1" align="justify">
    We are constantly looking for new ways to improve Matchworthee and we may unilaterally
update this Privacy Policy from time to time. When there is a significant change in this
Privacy Policy, you will see a pop up in the app which you will have to accept before you can
continue to use Matchworthee.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Storage and transfer of your personal data</Typography>
    <Typography variant="body1" align="justify">
    Your personal data may be hosted by our storage partners in servers located in countries
other than your country of residence.<br/><br/>
You can also view, edit or delete your Personal Data by logging in to your Matchworthee
account. To protect loss of data due to natural disasters and other such unforeseeable
circumstances, we store your details as encrypted backup for a safety retention as legally
required During this period, account information will be retained although the account will
of course not be visible on Matchworthee anymore.<br/><br/>
When you delete your account, your personal data is deleted after this retention window.
We only retain your email address and phone number in order to be able to contact you
with regard to payment matters. For internal use, we may store generic, non-personal data
regarding your activity as a user (number of people invited, number of logins, term of your
subscription, payment method, etc.).<br/><br/>
Matchworthee shall not collect data from minors. Should a Member lie about his/her date
of birth, and particularly if he/she fraudulently claims to be over 18 years of age, the parents
of the minor in question should inform Matchworthee of this by sending an e-mail
to team@Matchworthee.co, requesting the data to be deleted. Matchworthee commits to
deleting all data on the minor in question as soon as possible.<br/><br/>
When registering, the Member expressly consents to his/her data being transmitted to
Matchworthee service providers and subcontractors outside of the Member&#39;s country of
residence, to let Matchworthee run statistical studies, support users, and if need be, host
data processed by the Application.<br/><br/>
Matchworthee guarantees that the transfers are made under conditions ensuring the
confidentiality and security of the data and providing an adequate level of protection in
compliance with regulations.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">We may disclose your personal information</Typography>
    <Typography variant="body1" align="justify">
    <List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
   <ListItemText primary="In the event that we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
   <ListItemText primary="To identify, contact or bring legal action against you if you are violating our terms of use or other rules or if we believe you are causing injury or interference with the rights of users, the general public or Matchworthee" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
   <ListItemText primary="If we or substantially all of our assets are acquired by a third party, in which case personal information held by us will be a part of the transferred assets" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
   <ListItemText primary="If we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply our terms and other agreements or to protect our rights, property, or safety of our customers, or others" />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Who are we?</Typography>
    <Typography variant="body1" align="justify">
    Matchworthee, the accompanying website and app are owned by Anaitech Solutions Pvt
Ltd, 90B, Delhi-Jaipur Expressway, Sector 18, Gurgaon - 122008.<br/><br/>
We reserve the right, at our discretion, to make changes to the terms of use and privacy
policy at any time. The most updated version is always available on our App and Website.
Please check the terms of use and privacy policy periodically for changes as all modifications
are effective upon publication. Continued use of the Matchworthee app and website after
revision indicates the user&#39;s acceptance of the terms of use and privacy policy.<br/><br/>
In case you come across suspicious activity, immediately notify us at
team@Matchworthee.com
    </Typography>
  </Box>
 )
}

export default PP;
