import React,{ useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Box, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, OutlinedInput,
        InputAdornment,IconButton, Card, Grid, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
         
const Register = () => {
 const [userData, setUserData] = useState({ email: '', phone: '', username: '', password: '', DOB: dayjs('1995-01-01'), gender: '', confirmPassword: '' });

 const { email, phone, username, password, DOB, gender, confirmPassword } = userData;

 const onChange = e => {
   setUserData({ ...userData, [e.target.name]: e.target.value });
 };
 const [showPassword, setShowPassword] = React.useState(false);

 const handleClickShowPassword = () => setShowPassword((show) => !show);

 const handleMouseDownPassword = (event) => {
   event.preventDefault();
 };

 const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const login = async () => {
  const user = {
    email,
    password,
  };
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify(user);
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER}user/login`,
      body,
      config
    );
    localStorage.removeItem('userToken');
    localStorage.setItem('userToken', JSON.stringify(res.data.token));
    setAuthToken(res.data.token);
    const decoded = jwt_decode(res.data.token);

    window.location.href="/boot"
  } catch (err) {
    console.log(err.response.data)
  }
};

 const register = async () => {
   if (password !== confirmPassword) {
     alert('Passwords do not match');
     return;
   }
    const newUser = {
        email,
        phone,
        username,
        DOB,
        gender,
      password,
    };

const isAnyFieldEmpty = Object.values(newUser).some(field => field === '' || field === null || field === undefined);

if (isAnyFieldEmpty) {
  alert('all fields are required!')
} else {
  const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  const phoneNumberRegex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;

  if (emailRegex.test(newUser.email)) {
    if (phoneNumberRegex.test(newUser.phone)) {
      if (passwordRegex.test(newUser.password)) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const body = JSON.stringify(newUser);
      await axios.post(`${process.env.REACT_APP_SERVER}user/signup`, body, config);
      await login()
      // window.location.href="/login"
    } catch (err) {
        alert(err.response.data.message);
    }}
    else{
      alert('Password should be minimum 8 Characters with at least 1 number and 1 special Character!')
    }}
    else{
      alert('Enter a valid Phone Number!')
    }}
    else{
      alert('Enter a valid Email!')
    }
}
  };

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }
    return (
      <div>
      <Grid
        container
        justifyContent="center"
        alignItems="top"
        style={{ minHeight: '100vh', marginTop:'6vh' }}
      >
        <Box>
       <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt="MatchWorthee.com"
          style={{ height: '100px', objectFit: 'contain' }}
          image="./logoT.png"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            Register
          </Typography>
          <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/Login'}>
          login
        </Typography>
        </CardContent>
        <CardActions>
        <Grid container justifyContent="center" alignItems="center">
        <FormControl>
            <TextField label="Email" required fullWidth style={{marginTop:'1vh'}}  sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} variant="outlined" name="email" value={email} onChange={e => onChange(e)} placeholder="Email" />
            <TextField  label="Phone" required fullWidth style={{marginTop:'1vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} variant="outlined" name="phone" value={phone} onChange={e => onChange(e)} placeholder="Phone" />
            <TextField label="Display Name" required fullWidth style={{marginTop:'1vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} variant="outlined" name="username" value={username} onChange={e => onChange(e)} placeholder="Display Name" />
           
            <OutlinedInput
          id="outlined-password-input"
          label="Create Password"
          name="password"
          fullWidth
          value={password}
          onChange={(e) => onChange(e)}
          placeholder="Create Password"
          style={{marginTop:'3vh'}}
          type={showPassword ? 'text' : 'password'}
          sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
              {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
         
         />
          <Typography sx={{ opacity: '50%', fontSize:'12px' }}>*Password should be minimum 8 Characters with at least 1 number and 1 special Character</Typography>

            {/* <TextField label="Password" required fullWidth style={{marginTop:'1vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} type="password" name="password" value={password} onChange={e => onChange(e)} placeholder="Password" /> */}
            <TextField label="Confirm Password" required fullWidth style={{marginTop:'1vh', marginBottom:'3vh'}} sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} type="password" name="confirmPassword" value={confirmPassword} onChange={e => onChange(e)} placeholder="Confirm Password" />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
 <DatePicker
 fullWidth
 label="Date of Birth"
 type="date"
 name="DOB"
 value={userData.DOB}
 onChange={(newValue) => {
   const newValueDayjs = dayjs(newValue);
   setUserData(prevState => ({
     ...prevState,
     DOB: newValueDayjs
   }));
 }}
 views={["year","month","day"]}
 minDate={dayjs('1925-01-01')}
 maxDate={dayjs('2005-01-01')} 
 />
 </LocalizationProvider>
            <FormLabel style={{marginTop:'3vh'}}  id="demo-row-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup required row aria-labelledby="demo-row-radio-buttons-group-label" name="gender" value={gender} onChange={e => onChange(e)} >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
        </FormControl>
        <Typography gutterBottom variant="body2" align="center" component="div" sx={{color:"grey"}}>
      By Registering you are accept the  <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/PrivacyPolicy'}>
      Privacy Policy</Typography> & <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/TermsOfUse'}>
      Terms of Use
        </Typography>.
          </Typography>
            <Button style={buttonStyle} variant="outlined" onClick={register}>Register</Button>
        </Grid>
        </CardActions>
      </Card>
      </Box>
      </Grid>
      </div>
    );
};

export default Register;