import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import {Card, Grid, Box, CardContent, Typography, Button, Divider,
    Rating, CircularProgress, Stack } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { green } from '@mui/material/colors';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import SmoothScroll from 'smooth-scroll';


function AttachmentStyle() {
 const [userData, setUserData] = useState(null);
 const [phase, setPhase] = useState(0);
 const [shuffledQuestions, setShuffledQuestions] = useState([]);
 const [StageResult, setStageResult]=useState([]);
 const [Stage, setStage] = useState([
 {
   StageName:'Section 1: Early childhood - Mother, or Primary Caregiver#1',
   StageInfo:" How do you perceive the following conditions were met by your Mother, or Caregiver#1, in early childhood? \n\n For the following categories, consider your early childhood experience from your earliest childhood memories. Please select the answer that best represents your view. Answer ALL questions.\n\n",
   they:'Mother/Caregiver#1'
 },
 {
   StageName:'Section 2: Early childhood - Father, or Primary Caregiver#2',
   StageInfo:'How do you perceive the following conditions were met by your Father, or Caregiver#2, in early childhood? \n\n For the following categories, consider your early childhood experience from your earliest childhood memories. Please select the answer that best represents your view. Answer ALL questions.',
   they:'Father/Caregiver#2'
 },
 {
   StageName:'Section 3: Adulthood - Romantic partner',
   StageInfo:'Please answer the following questions with respect to your romantic partner or spouse. \n\n If you are currently not in a romantic relationship, please answer these questions with respect to how you felt in your most recent meaningful relationship. If you have never been in a romantic relationship before, imagine what such a relationship would be like. \n\n Please select the answer that best represents your view. Answer ALL questions.',
   they:' romantic partner or spouse'
 },
 {
   StageName:'Section 4: Adulthood - General',
   StageInfo:'Please read each of the following statements and rate the extent to which you believe each statement best describes your feelings about close relationships in general. You could keep in mind a close friend or a co-worker while answering the questions. \n\n Please select the answer that best represents your view. Answer ALL questions.',
   they:'other people'
 }
 ]);
 const [Questions, setQuestions] = useState([
 {
   "question": "I often worry that * do not really care for me.",
   "reverse": true
 },
 {
   "question": "I'm afraid that * may abandon me.",
   "reverse": true
 },
 {
   "question": "I find it easy to depend on *.",
   "reverse": false
 },
 {
   "question": "I worry that * won't care about me as much as I care about her/him.",
   "reverse": true
 },
 {
   "question": "I prefer not to show * how I feel deep down.",
   "reverse": false
 },
 {
   "question": "I usually discuss my problems and concerns with *.",
   "reverse": false
 },
 {
   "question": "It helps to turn to * in times of need.",
   "reverse": false
 },
 {
   "question": "I talk things over with *.",
   "reverse": false
 },
 {
   "question": "I don't feel comfortable opening up to *.",
   "reverse": true
 }
 ]);
 const [values, setValues] = useState([]);
 const [currentStage, setCurrentStage] = useState(0);
 const playAudio = () => {
  const audio = new Audio(process.env.PUBLIC_URL + '/audios/testFeedback.mp3');
  audio.volume = 0.3; // Set volume to 50%
  audio.play();
 }
  const playSubmit = (i) => {
    const audio = new Audio(process.env.PUBLIC_URL + '/audios/submit.mp3');
    audio.volume = 0.5; // Set volume to 50%
    audio.play();
    audio.addEventListener('ended', () => {
      scoot(i);
    });
   }
 var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 3700,
  easing: 'easeInOutCubic', 
 });


const handleChange = (index) => (event, newValue) => {
  setValues(prevValues => {
   const newValues = [...prevValues];
   if(Questions[index].reverse){
         if(newValue === 1) newValues[index]=1;
         if(newValue === 2) newValues[index]=2;
         if(newValue === 3) newValues[index]=3;
         if(newValue === 4) newValues[index]=4;
         if(newValue === 5) newValues[index]=5;
         if(newValue === 6) newValues[index]=6;
         if(newValue === 7) newValues[index]=7;
       } else{
         if(newValue === 1) newValues[index]=7;
         if(newValue === 2) newValues[index]=6;
         if(newValue === 3) newValues[index]=5;
         if(newValue === 4) newValues[index]=4;
         if(newValue === 5) newValues[index]=3;
         if(newValue === 6) newValues[index]=2;
         if(newValue === 7) newValues[index]=1;
       }
       return newValues;
  });
 };
 

 useEffect(() => { const userToken = localStorage.getItem('userToken');
 if (!userToken) return;
 const decoded = jwt_decode(userToken);
 const FetchData = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
    if (response.data && response.data.FirstBoot === false) {
      window.location.href='/MatchStackPage';
    } setUserData(response.data);
  }
  catch (error) { console.error(error); }
 }; FetchData(); 
 }, []); 

 function shuffleArray(array) {
 let currentIndex = array.length;
 let temporaryValue, randomIndex;
 while (0 !== currentIndex) {
     randomIndex = Math.floor(Math.random() * currentIndex);
     currentIndex -= 1;
     temporaryValue = array[currentIndex];
     array[currentIndex] = array[randomIndex];
     array[randomIndex] = temporaryValue;
 }return array;
}

 useEffect(() => {
  setShuffledQuestions(shuffleArray(Questions));
 }, []);

 const scoot = async (i) => {


  let AnxiousArray = values.filter((_, index) => !Questions[index].reverse)
  .map((respons) => respons);

  let AvodientArray = values.filter((_, index) => Questions[index].reverse)
  .map((respons) => respons);

  let doner=true;
  const average = array => array.reduce((a, b) => a + b) / array.length;
const AnxiousAverage=average(AnxiousArray)
const AvoidentAverage=average(AvodientArray)
console.log(AnxiousAverage)
console.log(AvoidentAverage)
  const Anxiousscore = AnxiousArray.reduce((accumulator, currentValue, index) => {
    if(currentValue===undefined){doner=false}
    return accumulator + currentValue;
  }, 0);

  const Avoidentscore = AvodientArray.reduce((accumulator, currentValue, index) => {
    return accumulator + currentValue;
  }, 0);

  if(doner===true){
   const result = (AnxiousAverage<=3.6) && (AvoidentAverage<=3.6) ? "Secure > Anxious:"+AnxiousAverage+" | Avoident:"+AvoidentAverage:
   (AnxiousAverage>=3.5) && (AvoidentAverage>=3.5) ? "Fearful > Anxious:"+AnxiousAverage+" | Avoident:"+AvoidentAverage:
   (AnxiousAverage<3.6) && (AvoidentAverage>3.5) ? "Dismissing > Anxious:"+AnxiousAverage+" | Avoident:"+AvoidentAverage:
   (AnxiousAverage>3.5) && (AvoidentAverage<3.6) ? "Preoccupied > Anxious:"+AnxiousAverage+" | Avoident:"+AvoidentAverage : "UNDEFINED > Anxious:"+AvoidentAverage+" | Avoident:"+AvoidentAverage

   // Store the current stage's values in the StageResult state
   setStageResult(prevResults => {
    const newResults = [...prevResults];
    newResults[currentStage] = [result];
    return newResults;
  });
  
    if (i === Stage.length) {
  } else {
      setCurrentStage(currentStage + 1);
      setValues([]);

    }

 }else{
  alert("Please answer all questions")
 }
};

const sendStageResultToServer = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let finResults = [...StageResult];
    // finResults[4]=['Final Results'];
    // if(finResults[3].value.startsWith('Secure')){
    //   alert('general Secure');
    // }else{
    //   alert('General Insecure');
    // }
    const stageResultString = JSON.stringify(await finResults);
      console.log(stageResultString)
    const body = {
      id: userData._id,
      score: stageResultString,
      test:"AS"
    };
    const res = await axios.post(`${process.env.REACT_APP_SERVER}user/update`, body, config );
    window.location.href='/State';
  } catch (err) {
    alert('Internal Error Occured!')
    window.location.href="/"
  }
 };

 useEffect(() => {
  window.scrollTo(0, 0);
 }, [currentStage]);
 
 useEffect(() => {
  if (currentStage === Stage.length) {
  sendStageResultToServer();
  }
 }, [StageResult, currentStage]);
 


 const init = async ()=>{
  setPhase(1);
  }
 const buttonStyle={
 display: 'flex', width: '343px', padding: '16px 32px', marginTop: '3vh',
 flexDirection: 'column', alignItems: 'center', gap: '12px',
 borderRadius: '100px', backgroundColor: '#D8234C', color:'white'
}

return (
  <div>
  { phase===0 ? (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }} >
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            <FavoriteIcon /> Attachment Style
          </Typography>
          <Typography gutterBottom variant="body1" align="center" component="div" style={{color:'grey'}}>
            The questionnaire is based on pathbreaking
            work done by <span style={{color:'#D8234C'}}>Dr Chris Fraley</span>, Professor at
            the University of Illinois's Department of
            Psychology.
            <br/><br/>
            Our Attachment Style describes how we intract and behave in our relationships.
            <br/><br/>
            <span style={{color:'#D8234C'}}> Duration: 5-7 mins</span><br/><br/>
            <Button align="center" onClick={() => init()}>Start</Button>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ) : phase===1 ? (
    <div style={{margin:'20px'}}>
      {Stage.map((stage, index) => (
        index === currentStage && (
          <div key={index}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, mb:1}} style={{ textAlign: 'center' }} >
            <b>{stage.StageName}</b>
            </Typography>
            <Typography align='left' variant="body2" style={{color:'grey', whiteSpace: 'pre-wrap' }}>
              {stage.StageInfo}
            </Typography><hr/>
            <Stack spacing={2} divider={<Divider flexItem />}>
              {shuffledQuestions.map((Question, qIndex) => {
                const question = Question.question.replace("*", stage.they);
                return (
                 <div key={qIndex} style={{ padding: '20px' }}>
                   <Typography key={`Q${qIndex}`} component="legend">{question}</Typography>
                   <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px'}} >
                     <Rating 
                       key={`rating${qIndex}`} 
                       name={`rating${qIndex}`} 
                       value={values[qIndex] || undefined} 
                       max={7}
                       style={{ color: '#D8234C', fontSize: '2.4em' }} 
                       icon={<CircleIcon fontSize="inherit" />}
                       emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />} 
                       onChange={(event, newValue) => {
                         if (newValue !== null && newValue !== undefined) {
                           handleChange(qIndex)(event, newValue);
                           scroll.animateScroll(window.scrollY + 190);
                         }
                       }}
                      //  onClick={() => {
                      //   playAudio();
                      //  }}
                       highlightSelectedOnly 
                     />
                   </Box>
                   <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2 }}>
                     <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Disagree</Typography>
                     <Typography sx={{ fontSize: '0.8rem', color: 'grey' }}>Strongly Agree</Typography>
                   </Box>
                 </div>
                );
              })}
            </Stack>
            <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:'20px', marginBottom:'40px'}} >
              <Button style={buttonStyle}onClick={()=>{playSubmit(index);}}> Submit </Button>
            </Box>
          </div>
        )
      ))}
    </div>
  ) : phase===5 ? (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '70vh' }}
    >
      <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" align="center" component="div">
            <FavoriteIcon  sx={{color: green[500]}} /> <b>Attachment Style</b>
          </Typography>
          <Typography gutterBottom variant="h6" align="left" component="div">
            Great!<br/><br/>You have finished with this questionnaire. Proceed to the next one please
          </Typography>
          <Button variant="h6" component={Link} to={'/State'}> Next</Button>
        </CardContent>
      </Card>
    </Grid>
  )}
 </div>
) 
  }
 export default AttachmentStyle;
 