import { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {  Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography, } from '@mui/material';

function Waitlist() {

  const [userData, setUserData] = useState(null);
  const decoded = jwt_decode(localStorage.getItem('userToken'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
          if (response.data) {
            setUserData(response.data);
            setLoading(false)
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
  }, [decoded.id]);


  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }

 return(

<Grid
container
justifyContent="center"
alignItems="top"
style={{ minHeight: '100vh', marginTop:'2vh' }}

>
<Box>
<Card style={{ border: "none", boxShadow: "none" }}>
<CardMedia
  component="img"
  alt="Payment"
  style={{ 
    width: '15em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}
  sx={{m:'30px'}}
  image="./waitlist.svg"
/>
<CardContent>
<Typography sx={{mt:"30px",ml:"30px",mr:"30px",}}>

We are delighted to have you  <span style={{color:'#D8234C'}}>@Matchworthee</span>. It would be our pleasure to help find deeply compatible companion you can have a serious relationship with. 
<br/><br/>
We await critical mass. Once we are ready, we shall notify you.  
<br/><br/>
Thank you!
</Typography>

</CardContent>

<CardActions>
<Grid container direction="column" justifyContent="center" alignItems="center">
 { 
   loading ? <></> :
   userData.StageState.ImFeedback === false ?
     <Button style={buttonStyle} onClick={() => { window.location.href="/FeedBack" }}>Immediate reaction</Button>
     : <></>
 }{
  loading ? <></> :
   userData.StageState.Faces === true ?
   <Button style={buttonStyle} onClick={() => { window.location.href="/ProfilePreview" }}>Go to Profile Preview</Button>
     : <></>
 }
</Grid>

      </CardActions>
</Card>
</Box></Grid>
 )
}

export default Waitlist;