import React from 'react';
import {Card, Grid, Box, CardActions,CardContent, CardMedia, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function Home() {
  const buttonStyle={
    display: 'flex',
    width: '343px',
    height: '51px',
    justifyContent: 'center',
    marginTop:'10px',
    borderRadius:'30px',
    alignItems: 'center',
    flexShrink: '0',
    backgroundColor:'#D8234C',
    color:'white'
  }

  return(
    <div>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Box>
     <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
     <CardMedia
      component="img"
      alt="MatchWorthee.com"
      image="./logoT.png"
      style={{ height: '120px', objectFit: 'contain' }}
      
      />

     <CardContent>
        <Typography gutterBottom variant="h5" align="center" component="div" sx={{mt:'30px'}}>
        Where serious relationships begin
        </Typography>
      </CardContent>
      <CardActions>
      <Grid
      container
      justifyContent="center"
      alignItems="center">
        <Button style={buttonStyle} component={Link} to={'/login'}>Login</Button>
        <Button  style={buttonStyle}  component={Link} to={'/register'}>Register</Button>
      </Grid>
      </CardActions>
    </Card>
    </Box>
    </Grid>
    </div>

  ); 
}

export default Home;
