import { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography} from '@mui/material';
// import Captcha from './Captcha';
import jwt_decode from 'jwt-decode';
const FeedBack = () => {
const isAuthenticated = !!localStorage.getItem('userToken');
const [UserData, setUserData] = useState(null);
const decoded = jwt_decode(localStorage.getItem('userToken'));
const [FormData, setFormData] = useState({ Likes: '', Missed: ''});

 const onChange = e => {
   setFormData({ ...FormData, [e.target.name]: e.target.value });
 };

 useEffect(() => {
   const fetchData = async () => {
     try {
     const response = await axios.get(`${process.env.REACT_APP_SERVER}user/min/${decoded.id}`);
     setUserData(await response.data);
    } catch (error){ console.error(error); } };
        fetchData(); }, [decoded.id]);

const RuleMaker = async () => {
    try {
        const config = {
        headers: {
            'Content-Type': 'application/json',
        },
        };

        let body = {
        id: UserData._id,
        ImFeedback:true
        };
        const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
        window.location.href="/WaitList"
    } catch (err) {
      alert('Internal Error Occured!')
      window.location.href="/"
    }};
 const submit = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const body = { id: UserData._id, feedback:{...FormData}};
      await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config);
      RuleMaker()
    } catch (err) {
        alert(err.response.data.message);
    }
  };

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }
    return (
      <div>
      <Grid
        container
        justifyContent="center"
        alignItems="top"
        style={{ minHeight: '100vh', marginTop:'6vh' }}
      >
        <Box>
       <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt="MatchWorthee.com"
          style={{ height: '100px', objectFit: 'contain' }}
          image="./logoT.png"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" align="center" component="div">
            Your immedidiate reaction
          </Typography>
        </CardContent>
        <CardActions>
        <Grid container justifyContent="center" alignItems="center">
        <TextField 
 label="What you like?" 
 fullWidth 
 style={{marginTop:'1vh'}} 
 sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} 
 variant="outlined" 
 name="Likes" 
 onChange={e => onChange(e)} 
 placeholder="What did you liked about the experience ..." 
 multiline 
 rows={4} 
/>

<TextField 
 label="What you miss?" 
 fullWidth 
 style={{marginTop:'1vh'}} 
 sx={{"& .MuiOutlinedInput-root.Mui-focused": {"& fieldset": {borderColor: '#D8234C',},},"& label.Mui-focused": {color: '#D8234C',},}} 
 variant="outlined" 
 name="Missed" 
 onChange={e => onChange(e)} 
 placeholder="What can be better, or you feel lacking ..." 
 multiline 
 rows={4} 
/>
<Button style={buttonStyle} variant="outlined" onClick={submit}>Submit</Button>
        </Grid>
        </CardActions>
      </Card>
      </Box>
      </Grid>
      </div>
    );
};

export default FeedBack;