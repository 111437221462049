import React from 'react';
import dayjs from 'dayjs';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Grid, Typography} from '@mui/material';
// import { Link, Route, Navigate } from 'react-router-dom';

function EMeets() {
  // const isAuthenticated = !!localStorage.getItem('userToken');
  const confirm = async () => {
    console.log('selected!!')
  }
  return(
  <div>
  <Grid
    container
    justifyContent="center"
    alignItems="top"
    style={{ maxHeight: '100vh', marginTop:'12vh' }}
  >
    <Box>
   
    <Typography gutterBottom variant="h5" align="center" component="div">
          Suggest a Slot
        </Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateTimePicker defaultValue={dayjs(Date.now())}  onAccept={confirm}/>
    </LocalizationProvider>
  </Box>
  </Grid>
</div>
 )
}

export default EMeets;
