import React, { useState, useEffect } from 'react';
import {Card, Grid, Box, CardActions,CardContent, CardMedia, Button, Typography } from '@mui/material';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

function Boot() {
  const [userData, setUserData] = useState(null);
  const [screen2, setScreen2] = useState(false);
  const decoded = jwt_decode(localStorage.getItem('userToken'));

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
          if (response.data && response.data.StageState.FirstBoot === true) {
            window.location.href='/State';
          }
          setUserData(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
  }, [decoded.id]);


  const RuleMaker = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let body = {
        id: userData._id,
        FirstBoot:true
      };
      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
      window.location.href='/State';
    } catch (err) {
      alert('Internal Error Occured!')
      window.location.href="/"
    }};

  const buttonStyle={
    display: 'flex',
    width: '343px',
    height: '51px',
    justifyContent: 'center',
    marginTop:'10px',
    borderRadius:'30px',
    alignItems: 'center',
    flexShrink: '0',
    backgroundColor:'#D8234C',
    color:'white'
  }

  return(
    <div>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Box>
     <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
     <CardMedia
      component="img"
      alt="MatchWorthee.com"
      image="./logoT.png"
      style={{ height: '100px', objectFit: 'contain' }}
      />
      { screen2===false ?<>
      <CardContent>
      <Typography gutterBottom variant="h4" align="left" component="div" sx={{mt:'50px',pl:'20px', pr:'20px'}}>
      We are a little different!
      </Typography>
    </CardContent>
   <CardContent>
      <Typography gutterBottom variant="body" align="left" sx={{pl:'20px', pr:'20px'}} component="div">
      We aren’t like other Matchmaking apps.<br/><br/>

Our goal is different – to find you matches you can
confidently have a serious relationship.<br/><br/>

Our registration process is long – we use a
science-driven approach to understand your
character strengths before suggesting matches.<br/><br/>
But hey, why not? A few extra minutes could be
worthwhile to find someone you can confidently explore
a relationship with.<br/><br/>
      </Typography>
    </CardContent>
    <CardActions>
    <Grid
    container
    justifyContent="center"
    alignItems="center">
      {/* <Button  style={buttonStyle} onClick={() => setScreen2(true)} >Start</Button> */}
      <Button  style={buttonStyle} onClick={ RuleMaker } >Let's begin.</Button>
    </Grid>
    </CardActions>
</>:

<>
<CardContent>
      <Typography gutterBottom variant="body" align="center" component="div">
     
      </Typography>
    </CardContent>
    <CardActions>
    <Grid
    container
    justifyContent="center"
    alignItems="center">
    </Grid>
    </CardActions></>
      }
    </Card>
    </Box>
    </Grid>
    </div>

  ); 
}

export default Boot;

