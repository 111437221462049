import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {  Grid, List, ListItem, Divider, ListItemText,
          ListItemAvatar, Avatar, Typography, Skeleton, Button } from '@mui/material';

// import { Link, Route, Navigate } from 'react-router-dom';

function MatchStackPage() {
  // const isAuthenticated = !!localStorage.getItem('userToken');
  const [userData, setUserData] = useState(null);
    const decoded = jwt_decode(localStorage.getItem('userToken'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}user/ReturnStack/${decoded.id}`);
                setUserData(response.data);
                console.log(response.data)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [decoded.id]);

const Intrested = async (i)=>{
 console.log('Intersted '+i) 
}

const SaveFL = async (i)=>{
  console.log(i+' Save for Later!') 
 }
 return(
  <Grid
    container
    justifyContent="center"
    alignItems="top"
    style={{ marginTop:'1vh', marginBottom:'7vh' }}
  >
    <List sx={{ width: '95%',  bgcolor: 'background.paper' }}>
      {userData && userData.map((user, index) => (
        <React.Fragment key={index}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="~" src={user.face ? 'data:image/jpeg\;base64,'+user.face : 'https://i.stack.imgur.com/YaL3s.jpg'} />
            </ListItemAvatar>
            <ListItemText
              primary={user.Constants.username ? user.Constants.username : <Skeleton  animation="wave"  variant="text" width={180} sx={{ fontSize: '1rem' }} />}
              secondary={
                <div>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {user.Constants.gender ? user.Constants.gender : <Skeleton  animation="wave"  variant="text" width={50} sx={{ fontSize: '1rem' }} />},
                  </Typography>
                  {user.BIO ? '- '+user.BIO : 'Hello there!'}
                  <Grid container justifyContent="center">
                    <Button onClick={() => Intrested(user.Constants.username)}>Interested</Button>
                    <Button onClick={() => SaveFL(user.Constants.username)}>Maybe Later</Button>
                  </Grid>
                </div>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  </Grid>
)
}

export default MatchStackPage;
