import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {  Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography, } from '@mui/material';

function Paywall() {
  const [userData, setUserData] = useState(null);
  const [variant, setVariant] = React.useState(undefined);
  const decoded = jwt_decode(localStorage.getItem('userToken'));
  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
              if (response.data && response.data.StageState.Money === true && response.data.StageState.Profile===false) {
                window.location.href='/CompleteProfile';
              }else if (response.data && response.data.StageState.Money === true && response.data.StageState.Profile===true){
                window.location.href='/PIT';
              }
              setUserData(response.data);
          } catch (error) {
              console.error(error);
          }
      };
      fetchData();
  }, [decoded.id]);
  useEffect(() => {
    if (variant) {
      handlePayment();
    }
   }, [variant]);
  let handlePayment=()=>{
    setTimeout(function() {
      RuleMaker()
     
    }, 1500);
    
  }

  const RuleMaker = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let body = {
        id: userData._id,
        Money:true
      };
      const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
      window.location.href='/CompleteProfile';
    } catch (err) {
      console.log(err.response.data)
    }};
   

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }

  
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="top"
      style={{ minHeight: '100vh', marginTop:'20vh' }}
    >
      <Box>
     <Card style={{ border: "none", boxShadow: "none" }}>
      <CardMedia
        component="img"
        alt="Payment"
        style={{ 
          width: '18em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        image="./payment.svg"
      />
      <CardContent>
        <Typography gutterBottom variant="h2" align="center" component="div">
        ₹4999
        </Typography>
        <Typography gutterBottom variant="h5" align="center" component="div">
        for a 3 Month Subscription
        </Typography>

      </CardContent>
      <CardActions>
      <Grid container justifyContent="center" alignItems="center">


      <Button style={buttonStyle} onClick={() => { setVariant('solid'); }}>No Payment Required, Click to proceed.</Button>
      <Typography gutterBottom variant="body2" align="left" style={{color:'grey'}} component="div" sx={{mt:"30px"}}>
        *No payment required!<br/>
Access to Matchworthee is FREE<br/> for limited period.
        </Typography>
      </Grid>
      </CardActions>
    </Card>
        </Box>
    <Modal open={!!variant} onClose={() => setVariant(undefined)}>
        <ModalDialog  sx={{backgroundColor:"green"}} variant={variant}>
         
          <DialogTitle>Payment Successful</DialogTitle>
          <Box  display="flex"
                justify="center"
                align="center"
            >
          <CheckCircleIcon sx={{   fontSize: 70, 
    position: 'relative',
    top: '50%', 
    left: '35%',
  }}/>
          </Box>
          <DialogContent>Redirecting you to the next step.</DialogContent>
        </ModalDialog>
      </Modal>
    </Grid>

  );

}
export default Paywall;
