import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {  TextField, OutlinedInput, InputAdornment, IconButton,
          Box, Button, Card, Grid, CardActions, CardContent, CardMedia, Typography,
          Snackbar, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const PasswordReset = () => {
  const [userData, setUserData] = useState({ phone: '', YOB: '', NewPass:'' });

  const { phone, YOB, NewPass } = userData;

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const reset = async () => {
    const user = {
      phone,
      YOB,
      NewPass,
    };
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify(user);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}user/PassReset`,
        body,
        config
      );
      alert('Password Reset Sucessfully!');
      window.location.href="/Login"
    } catch (err) {
      setOpenSnackbar(true);
      console.log(err.response.data)
    }
    // console.log(user)
  };

  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }

const [openSnackbar, setOpenSnackbar] = React.useState(false);


  return (
    <div>
    <Grid
      container
      justifyContent="center"
      alignItems="top"
      style={{ minHeight: '100vh', marginTop:'20vh' }}
    >
      <Box>
     <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="Matchworthee.com"
        style={{ height: '100px', objectFit: 'contain' }}
        image="./logoT.png"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" align="center" component="div">
          Reset your Password
        </Typography>
        <Typography gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/Login'}>
        Login
        </Typography>
      </CardContent>
      <CardActions>
      <Grid container justifyContent="center" alignItems="center">

        <TextField
          id="outlined-basic"
          label="Phone"
          variant="outlined"
          fullWidth
          name="phone"
          value={phone}
          onChange={(e) => onChange(e)}
          placeholder="phone"
          style={{marginTop:'1vh'}}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& fieldset": {
                borderColor: '#D8234C',
              },
            },
            "& label.Mui-focused": {
              color: '#D8234C',
            },
          }}
         />

<LocalizationProvider dateAdapter={AdapterDayjs}>
 <DatePicker
 sx={{mt:'3vh'}}
 label="Year of Birth"
 type="date"
 name="YOB"
 value={userData.DOB}
 onChange={(newValue) => {
   const newValueDayjs = dayjs(newValue);
   const year = newValueDayjs.year();
   setUserData(prevState => ({
     ...prevState,
     YOB: year
   }));
 }}
 minDate={dayjs('1925-01-01')}
 maxDate={dayjs('2005-01-01')}
 views={['year']}
 slotProps={{ textField: { fullWidth: true } }}
 />
</LocalizationProvider>



        <OutlinedInput
          id="outlined-password-input"
          label="Create New Password"
          name="NewPass"
          fullWidth
          value={NewPass}
          onChange={(e) => onChange(e)}
          placeholder="Create New Password"
          style={{marginTop:'3vh'}}
          type={showPassword ? 'text' : 'password'}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& fieldset": {
                borderColor: '#D8234C',
              },
            },
            "& label.Mui-focused": {
              color: '#D8234C',
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
              {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
         
         />
        <Button style={buttonStyle} onClick={reset}> Reset Password </Button>
      </Grid>
      </CardActions>
    </Card>
    <Snackbar open={openSnackbar} autoHideDuration={6000}  onClose={() => setOpenSnackbar(false)}>
        <Alert severity="error">Data Do Not Match!</Alert>
    </Snackbar>
    </Box>
    </Grid>
    </div>
  );
};

export default PasswordReset;
