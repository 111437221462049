import React from 'react';
// import { Link, Route, Navigate } from 'react-router-dom';
import { Box, Button, Card, Grid,Avatar, Chip, CardActions, CardContent, CardMedia, Typography, } from '@mui/material';

function Share() {
  // const isAuthenticated = !!localStorage.getItem('userToken');
  const buttonStyle={
    display: 'flex',
    width: '343px',
    padding: '16px 32px',
    marginTop: '3vh',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '100px',
    backgroundColor: '#D8234C',
    color:'white'
  }
 return(
  <Grid
container
justifyContent="center"
alignItems="top"
style={{ minHeight: '100vh', marginTop:'2.5vh' }}

>
<Box>
<Typography variant="h5" align="center" component="div" sx={{mt:'20px',mb:'20px', color:'#D8234C'}}>
         app.matchworthee.com
       </Typography>
       <Card sx={{m:'25px', backgroundColor:'#Fdfcef'}}>
 <CardMedia
  component="img"
  image={process.env.PUBLIC_URL + './shareCode.png'}
  sx={{ maxWidth: '450px' }}
 />
 <CardContent>  
  <Typography variant="body2" color="text.secondary">
   </Typography>
 </CardContent>
</Card>


<Grid container direction="column" justifyContent="center" alignItems="center">

<Button style={buttonStyle} alignItems="center" onClick={() => { window.location.href="/myProfile" }}>Go to my Profile</Button>
</Grid>
</Box>
</Grid>
 )
}

export default Share;
