import React, { useState, useEffect, componentDidMount } from 'react';
import { ComposableMap, ZoomableGroup, Geographies, Geography, Marker } from 'react-simple-maps';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SchoolIcon from '@mui/icons-material/School';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import IconButton from '@mui/joy/IconButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Box, Typography, Avatar, Button, Skeleton, Snackbar, Alert, 
          TextField, FormControl, InputLabel, Select, MenuItem, ListSubheader, Slider,
          Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, FormControlLabel } from '@mui/material';


function CompleteProfile() {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isStudent, setStudent] = useState("false");
  const [location, setLocation] = useState(null);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');
  const [empForm, setEmpForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localEdu, setlocalEdu] = useState({});
  const [EDUpart, setEDUpart] = React.useState(false);
  const [eduNat, seteduNat]=useState(false);
  const [Dig, setDig]=useState(true);
  const [Dip, setDip]=useState(true);
  const [CanLocate, setCanLocate]=useState(false);
  const decoded = jwt_decode(localStorage.getItem('userToken'));

// form Hydration state
const [formState, setFormState] = useState({
  fName: '',
  lName: '',
  Height: '',
  Weight: '',
  MaritalStatus: '',
  HaveChildren: '',
  SexualOrientation: '',
  DWC:false,
  DNC:false,
  WWC:false,
  WNC:false,
  Profession: '',
  NatureOfEmployment: '',
  StateOfEmployment:'',
  LinkedinURI:'',
  Country: '',
  City: '',
  Area: '',
  AreaPin: '',
  Coloney:'',
  Coords:[],
  Education: [] });


  useEffect(() => {
    const fetchEmpForm = async () => {
      const response = await axios.post(`${process.env.REACT_APP_SERVER}user/PProfile`);
      const data = response.data;
      setEmpForm(data);
    }; fetchEmpForm(); }, []);

    function valueText(value) {
      return `${value} feet`;
    }


    useEffect(() => {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) return;
    
      const decoded = jwt_decode(userToken);
      const FetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER}user/${decoded.id}`);
          if (response.data && response.data.StageState.Exams === false) {
            window.location.href='/state';
          }
        } catch (error) {
          console.error(error);
        }
      };
      FetchData();
    }, []); 

  useEffect(() => {
    const fetchData = async () => { try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}user/min/${decoded.id}`);
      setUserData(await response.data);
        setFormState(
          { ...formState, 
            fName:response.data.Constants.fName ||'',
            lName:response.data.Constants.lName || '',
            Height:response.data.BMI?.Height || NaN,
            Weight:response.data.BMI?.Weight || NaN,
            MaritalStatus:response.data.Practs?.MaritalStatus || '',
            HaveChildren:response.data.Practs?.HaveChildren || '',
            SexualOrientation:response.data.Practs?.SexualOrientation || '',
            DWC:response.data.Practs?.NoNos?.DWC || false,
            DNC:response.data.Practs?.NoNos?.DNC || false,
            WWC:response.data.Practs?.NoNos?.WWC || false,
            WNC:response.data.Practs?.NoNos?.WNC || false,
            Profession:response.data.Employeement?.Profession || '',
            NatureOfEmployment:response.data.Employeement?.NatureOfEmployment || '',
            StateOfEmployment:response.data.Employeement?.StateOfEmployment ||'',
            LinkedinURI:response.data.Employeement?.LinkedinURI ||'',
            Country:response.data.Location?.Country || '',
            City:response.data.Location?.City || '',
            Area:response.data.Location?.Area || '',
            AreaPin:response.data.Location?.AreaPin || '',
            Coloney:response.data.Location?.Coloney ||'',
            Education: response.data.Education || []
          }
        )

        if(response.data.Education != null){
          response.data.Education.forEach(function(item) {
          if(item.NatureOfStudies==='Degree'){
            setDig(prevDig => false)
          }else if(item.NatureOfStudies==='Diploma'){
            setDip(prevDip => false)
          }else{
            setDig(prevDig => true);
            setDip(prevDip => true);
          }
          });
         }
         
        setLoading(false);

      } catch (error){ console.error(error); } }; fetchData(); }, [decoded.id]);



      const handleEMPOpen = (e) => {
        if ((e === 'Degree' && !formState.Education.some(edu => edu.NatureOfStudies === 'Degree')) ||
            (e === 'Diploma' && !formState.Education.some(edu => edu.NatureOfStudies === 'Diploma'))) {
          setlocalEdu({...localEdu, 'NatureOfStudies': e });
          if(Dig && e==='Degree'){
            setDig(false);
            seteduNat(true);
            setEDUpart(true);
          }
          if(Dip && e==='Diploma'){
            setDip(false);
            seteduNat(true);
            setEDUpart(true);
          }
        }
       };
       
  
       const handleEMPClose = () => {
        if('NatureOfStudies' in localEdu && localEdu.NatureOfStudies === 'Degree'){
          setDig(true);
        }
        if('NatureOfStudies' in localEdu && localEdu.NatureOfStudies === 'Diploma'){
          setDip(true);
        }
        setlocalEdu({})
        setEDUpart(false);
        seteduNat(false);
       };
       
    
    const handleEMPAdd = ()=>{
        setFormState(prevState => ({
          ...prevState,
          Education: [...prevState.Education, localEdu]
        }));
        if (localEdu.NatureOfStudies === 'Degree') {
          setDig(false);
        }
        if (localEdu.NatureOfStudies === 'Diploma') {
          setDip(false);
        }
        setlocalEdu({})
      handleEMPClose();
    }
    const handleEMPRemove = (index) => {
      setFormState((prevState) => ({
        ...prevState,
        Education: prevState.Education.filter((_, i) => i !== index),
      }));
      if(formState.Education.filter((_, i) => i === index)[0].NatureOfStudies === 'Degree'){
        setDig(true)
      }
      if(formState.Education.filter((_, i) => i === index)[0].NatureOfStudies === 'Diploma'){
        setDip(true)
      }
     };
     

    const handleEduNatureChange = (e) => {
      setlocalEdu({...localEdu, [e.target.name]: e.target.value });
    };
    
    const handleChange = (e) => {
      if (e.target.type === "checkbox") {
        setFormState({ ...formState, [e.target.name]: e.target.checked });
      }else if(e.target.type === "text"){
        setFormState({
          ...formState,
          [e.target.name]: e.target.value
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
         });

      } else {
        setFormState({ ...formState, [e.target.name]: e.target.value});

      }
     };
    const handleConstants =(what,type)=>() => { setMessage(what); setType(type); setOpen(true); };
    const handleClose = (event, reason) => { if (reason === 'clickaway') { return; } setOpen(false);};
    const handleSubmit = async (e) => { e.preventDefault();
      const HWRegex =  /^[\d.]+$/;
      const NamesRegex = /^[A-Za-z]+$/;
      if ((HWRegex.test(formState.Height) || formState.Height==='') && (HWRegex.test(formState.Weight) || formState.Weight==='')) {
        if ((NamesRegex.test(formState.fName) || formState.fName==='') && (NamesRegex.test(formState.lName)  || formState.lName==='')) {
          if (HWRegex.test(formState.AreaPin) || formState.AreaPin==='') {
            if(formState.Education.some(education => education.NatureOfStudies === 'Degree')) {
            // const isFormValid = Object.values(formState).every(field => field !== '');
            //   if (!isFormValid) {
            //     alert('All fields are required');
            //     return;
            //   }
            try {
            const config = { headers: { 'Content-Type': 'application/json', }, };
            const body = { id: userData._id, ...formState, Profile:true };
            const res = await axios.post(`${process.env.REACT_APP_SERVER}user/updateProfile`, body, config );
            if(res.status===200){ 
              window.location.href="/PIT";
            } } catch (err) {
              alert('Internal Error Occured!')
              window.location.href="/"}
          }
          else{
            alert("Adding Degree Education is mandetory")
          }}
    else{
      alert("Area Pin cannot contain alphabets or Special Symbols!")
    }}
    else{
      alert("First & Last Name Cannot contain Numbers or Special Symbols!")
    }}
    else{
      alert("Height and Weight can only be numeric values!")
    }
      
    };

    useEffect(() => {
     }, [Dig, Dip]);

     
    useEffect(() => {
      if ("geolocation" in navigator) {
        setCanLocate(true)
      }
     }, []);

     let locateMe  = async ()=>{
      navigator.geolocation.getCurrentPosition(function(position) {
        setLocation(position);
        setFormState({ ...formState, Coords:[position.coords.latitude, position.coords.longitude] });
      });
     }

     

const buttonStyle={
  display: 'flex', width: '343px', height: '51px', justifyContent: 'center', marginTop:'20px',
  marginBottom:'20px', borderRadius:'30px', alignItems: 'center', flexShrink: '0', backgroundColor:'#D8234C', color:'white' }
 
return (
  <Box style={{marginTop:'3vh'}} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, p: 3, borderRadius: 2 }}>
    <form onSubmit={handleSubmit}>        
    <Typography variant="h4">{userData ? userData.Constants.username : <Skeleton variant="text" width={180} sx={{ fontSize: '2rem' }} />}</Typography>
    <TableContainer><Table aria-label="simple table">
      <TableBody>
        <TableRow onClick={handleConstants('Email can\'t be changed!','error')}>
          <TableCell><b>Email:</b></TableCell>
          <TableCell align="left">{userData ? userData.Constants.email : <Skeleton  animation="wave"  variant="text" width={180} sx={{ fontSize: '1rem' }} />}</TableCell>
        </TableRow>
        <TableRow onClick={handleConstants('Phone No. can\'t be changed!','error')}>
          <TableCell><b>Phone:</b></TableCell>
          <TableCell align="left">{userData ? userData.Constants.phone : <Skeleton  animation="wave"  variant="text"  width={180}  sx={{ fontSize: '1rem' }} />}</TableCell>
        </TableRow>
        <TableRow  onClick={handleConstants('Gender can\'t be changed!','error')}>
          <TableCell><b>Gender:</b></TableCell>
          <TableCell align="left">{userData ? userData.Constants.gender : <Skeleton  animation="wave"  variant="text" width={40} sx={{ fontSize: '1rem' }} />}</TableCell>
        </TableRow>
        <TableRow  onClick={handleConstants('Birth Date can\'t be changed!','error')}>
          <TableCell><b>Date of Birth:</b></TableCell>
          <TableCell align="left">{userData ? new Date(userData.Constants.DOB).toLocaleDateString('IST', { day: '2-digit', month: 'short',  year: 'numeric' }) : <Skeleton  animation="wave"  variant="text" width={60} sx={{ fontSize: '1rem' }} />}</TableCell>
        </TableRow>
        </TableBody>
      </Table></TableContainer>
    <TableContainer><Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField id="fName" required name="fName" fullWidth label="First Name" variant="outlined" value={formState.fName} onChange={handleChange}   />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField id="lName" required name="lName" fullWidth label="Last Name" variant="outlined" value={formState.lName} onChange={handleChange} />
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell>
          <FormControl fullWidth style={{ display: 'flex', justifyContent: 'space-between' }}>
   <TextField id="Height" required fullWidth InputProps={{ inputProps: { min: 60, max: 260 } }}  name="Height" type="number" label="Height (in cm)" variant="outlined" value={formState.Height} onChange={handleChange} />
</FormControl>
            </TableCell>
            <TableCell>
            <TextField id="Weight" required fullWidth InputProps={{ inputProps: { min: 30, max: 200 } }}  type="number" name="Weight" label="Weight (in Kgs)" variant="outlined" value={formState.Weight} onChange={handleChange}  />
            </TableCell>
          </TableRow>
          <TableRow><TableCell sx={{ borderBottom: 'none'}} colSpan={2}>*Present Marital Status :</TableCell></TableRow>
          <TableRow>
            <TableCell>
            <input type="radio"  name="MaritalStatus" value="Single" checked={formState.MaritalStatus === 'Single'}  onChange={handleChange} /> Single<br/>
            <input type="radio" name="MaritalStatus" value="Married" checked={formState.MaritalStatus === 'Married'}  onChange={handleChange} /> Married
            </TableCell>
            <TableCell align="left">
            <input type="radio" name="MaritalStatus" value="Divorced" checked={formState.MaritalStatus === 'Divorced'}  onChange={handleChange} /> Divorced<br/>
            <input type="radio" name="MaritalStatus" value="Widowed" checked={formState.MaritalStatus === 'Widowed'}  onChange={handleChange} /> Widowed
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>*Have Children ? </TableCell>
            <TableCell align="left">
              <input type="radio" name="HaveChildren" value="true" checked={formState.HaveChildren === 'true'} onChange={handleChange} /> Yes
              <input type="radio" name="HaveChildren" value="false" checked={formState.HaveChildren === 'false'} onChange={handleChange} /> No
            </TableCell>
          </TableRow>
          <TableRow><TableCell sx={{ borderBottom: 'none'}} colSpan={2}>*Sexual Orientation: </TableCell></TableRow>
          <TableRow>
            <TableCell>
            <input type="radio" name="SexualOrientation" value="straight" checked={formState.SexualOrientation === 'straight'} onChange={handleChange} /> Straight<br/>
              <input type="radio" name="SexualOrientation" value="biSexual" checked={formState.SexualOrientation === 'biSexual'} onChange={handleChange} /> Bi-Sexual
            </TableCell>
            <TableCell align="left">
              <input type="radio" name="SexualOrientation" value="Gay" checked={formState.SexualOrientation === 'Gay'} onChange={handleChange} /> Gay<br/>
              <input type="radio" name="SexualOrientation" value="Lesbian" checked={formState.SexualOrientation === 'Lesbian'} onChange={handleChange} /> Lesbian
            </TableCell>
          </TableRow>
          <TableRow><TableCell sx={{ borderBottom: 'none'}} colSpan={2}>Are you open to a relationship with: </TableCell></TableRow>
          <TableRow>
  <TableCell colSpan={2}>
    <FormControlLabel control={<Checkbox name="DWC" checked={formState.DWC} onChange={handleChange}/>}label="Divorcee with Children"/>
    <FormControlLabel control={<Checkbox name="DNC" checked={formState.DNC} onChange={handleChange}/>}label="Divorcee without Children"/>
    <FormControlLabel control={<Checkbox name="WWC" checked={formState.WWC} onChange={handleChange}/>}label="Widowed with Children"/>
    <FormControlLabel control={<Checkbox name="WNC" checked={formState.WNC} onChange={handleChange}/>}label="Widowed without Children"/>
  </TableCell>
          </TableRow>
          <TableRow>
          <TableCell colSpan={2}>
          *Residence
          </TableCell>
        </TableRow>
          {loading ? <Skeleton  animation="wave"  variant="text" width={180} sx={{ fontSize: '1rem' }} />:
            <TableRow>
              <TableCell>
              <FormControl fullWidth>
                  <InputLabel id="Country">Country</InputLabel>
                  <Select labelId="Country" required id="Country" value={formState.Country} name="Country" label="Country"
                    onChange={handleChange} >
                      {empForm.Country.map((item, index) => (
                      <MenuItem key={index} value={item}>{item}</MenuItem>))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align="left">
              <TextField id="City" required name="City" fullWidth label="City" variant="outlined" value={formState.City} onChange={handleChange} />
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <TextField align="left" required id="Area" name="Area" fullWidth label="Area" variant="outlined" value={formState.Area} onChange={handleChange} />
              </TableCell>
              <TableCell >
                <TextField id="AreaPin" required name="AreaPin" fullWidth label="Pin Code" variant="outlined" value={formState.AreaPin} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <TextField id="Coloney" name="Coloney" fullWidth label="Apartment / Road / Building / Colony" variant="outlined" value={formState.Coloney} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
              {
              CanLocate ? ( <Button onClick={locateMe}>Allow Current Location Access</Button>):<></>}
              {
              location ? (
                <>
<ComposableMap>
    <ZoomableGroup>
      <Geographies geography={"https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries.geojson"}>
        {({ geographies }) =>
          geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
        }
      </Geographies>
      <Marker coordinates={[location.coords.longitude, location.coords.latitude]}>
        <circle r={5} fill="orange" />
      </Marker>
    </ZoomableGroup>
  </ComposableMap>

</>  ) : (
                <></>
              )
          
            }
              </TableCell>
            </TableRow>
            <TableRow><TableCell sx={{ borderBottom: 'none'}} colSpan={2}>Education: </TableCell></TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {Dig ? 
               <Button variant="outlined" onClick={Dig ? () => handleEMPOpen('Degree') : null}
                      disabled={!Dig ? true : false}
                      sx={Dig ? {borderColor:'#D8234C', color:'#D8234C', m:1} : {borderColor:'grey', color:'grey', m:1}}
                      startIcon={<AddCircleIcon />}>*Add Highest Degree</Button>
               :<></>
              }
              {Dip ?
<Button variant="outlined" onClick={Dip ? () => handleEMPOpen('Diploma') : null}
        disabled={!Dip ? true : false}
        sx={Dip ? {borderColor:'#D8234C', color:'#D8234C', m:1} : {borderColor:'grey', color:'grey', m:1}}
        startIcon={<AddCircleIcon />}>Add Highest Diploma</Button>
            :<></>
            }

              <Dialog open={EDUpart} onClose={handleEMPClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Add Highest Education</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Provide only the Highest Qualification here
          </DialogContentText>
                  { (eduNat) ? localEdu.NatureOfStudies==="Degree" ?
                      <FormControl fullWidth>
                      <InputLabel id="Degree">Degree Type</InputLabel>
                      <Select labelId="Degree" id="Degree" name="Degree" label="Degree Type"
                        onChange={handleEduNatureChange} >
                        {empForm.Degree.map((item, index) => 
                          item.startsWith('*') ? 
                            <ListSubheader  sx={{ backgroundColor: '#737373', color:'white' }} key={index}>
                              <strong>{item.slice(1)}</strong>
                            </ListSubheader> :
                            <MenuItem key={index} value={item}> {item} </MenuItem> )}
                      </Select>
                    </FormControl>
                    :
                    <FormControl fullWidth>
                    <InputLabel id="Diploma">Diploma Type</InputLabel>
                    <Select labelId="Diploma" id="Diploma" name="Diploma" label="Diploma Type"
                      onChange={handleEduNatureChange} >
                      {empForm.Diploma.map((item, index) => 
                          <MenuItem key={index} value={item}> {item} </MenuItem> )}
                    </Select>
                  </FormControl>
                  :<></>
                  }
                  {
                     Object.keys(localEdu).length>1 ?
                      <Table>
                        {loading ? <Skeleton  animation="wave"  variant="text" width={180} sx={{ fontSize: '1rem' }} />:
                        <TableBody>
                           <TableRow>
              <TableCell colSpan={2}>
              <TextField id="Institution" name="Institution" required fullWidth label="College/University affiliation" variant="outlined" onChange={handleEduNatureChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
              <FormControl fullWidth>
                  <InputLabel id="Country">Country</InputLabel>
                  <Select required labelId="Country" id="Country" name="Country" label="Country"
                    onChange={handleEduNatureChange} >
                      {empForm.Country.map((item, index) => (
                      <MenuItem key={index} value={item}>{item}</MenuItem>))}
                  </Select>
                </FormControl>
              </TableCell>
              </TableRow>
              <TableRow>
              <TableCell colSpan={2}>
              <TextField id="City" name="City" required fullWidth label="City" variant="outlined" onChange={handleEduNatureChange} />
              </TableCell>
            </TableRow>
                        </TableBody>
                      }</Table>
                     :<></>
                  }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleEMPClose}>Cancel</Button>
          <Button onClick={handleEMPAdd}>Add</Button>
        </DialogActions>
      </Dialog>

              <List component="nav" sx={{ maxWidth: 320,}}>
              {Array.isArray(formState.Education) && formState.Education.map((item, index) => (
              <ListItem role="none" endAction={
                  <IconButton aria-label="Delete" size="sm" color="danger" onClick={() => handleEMPRemove(index)}>
                    <DeleteOutlineIcon /> </IconButton> }>
                <ListItemButton>
                  <ListItemDecorator>
                    {item.NatureOfStudies==='Degree' ? <SchoolIcon /> : <HistoryEduIcon/>}
                  </ListItemDecorator>
                  {item.NatureOfStudies}, {Object.values(item)[1]}
                </ListItemButton>
                </ListItem>
                ))}
               </List>
              </TableCell>
 
            </TableRow>
            <TableRow><TableCell sx={{ borderBottom: 'none'}} colSpan={2}>Profession Portfolio: </TableCell></TableRow>
            <TableRow>
              <TableCell colSpan={2}>
              <FormControl fullWidth>
                  <InputLabel  id="StateOfEmployment">Nature of Employment</InputLabel>
                  <Select required labelId="StateOfEmployment" value={formState.StateOfEmployment} id="StateOfEmployment" name="StateOfEmployment" label="State of Employment"
                    onChange={handleChange}>
                      <MenuItem key='Working' value='Working'> Working </MenuItem>
                      <MenuItem key='Not Working' value='Not Working'> Not Working </MenuItem>
                      <MenuItem key='Never Worked' value='Never Worked'> Never Worked </MenuItem>
                      <MenuItem key='Retired' value='Retired'> Retired </MenuItem>
                      <MenuItem key='Student' value='Student'> Student </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              </TableRow>
              <TableRow>
            {formState.StateOfEmployment === "Working" || formState.StateOfEmployment === "Retired"  || formState.StateOfEmployment === "Not Working"  ? 
              <TableCell colSpan={2}>
              <FormControl fullWidth>
                  <InputLabel id="Profession">{formState.StateOfEmployment === "Retired"  || formState.StateOfEmployment === "Not Working"  ? "Past" : ""} Profession</InputLabel>
                  <Select required  labelId="Profession" value={formState.Profession} id="Profession" name="Profession" label="Profession"
                    onChange={handleChange}>
                      {empForm.Profession.map((item, index) =>  
                        <MenuItem key={index} value={item}> {item} </MenuItem> 
                      )}
                  </Select>
                </FormControl>
              </TableCell>
              :<></> }
            </TableRow>
            <TableRow>
            {(formState.StateOfEmployment!=="Student" && formState.StateOfEmployment!=="Never Worked") && formState.Profession !== "" ? 
              <TableCell colSpan={2}>
              <FormControl fullWidth>
                  <InputLabel id="NatureOfEmployment">Nature Of Employment</InputLabel>
                  <Select required labelId="NatureOfEmployment" value={formState.NatureOfEmployment} id="NatureOfEmployment" name="NatureOfEmployment" label="NatureOfEmployment"
                    onChange={handleChange}>
                      {empForm.NatureOfEmployment.map((item, index) =>  
                        <MenuItem key={index} value={item}> {item} </MenuItem> 
                      )}
                  </Select>
                </FormControl>
              </TableCell>
              :<></> }
            </TableRow>
            <TableRow>
            <TableCell colSpan={2}>
              <TextField id="LinkedinURI" name="LinkedinURI" fullWidth label="Enter your Linkedin Profile URL" variant="outlined" value={formState.LinkedinURI} onChange={handleChange}   />
            </TableCell>
          </TableRow>
          </TableBody>
      </Table>
    </TableContainer>
    <Button variant="contained"  style={buttonStyle} type="submit">Save & Continue</Button>
    <Snackbar open={open} autoHideDuration={2000}  onClose={handleClose}>
      <Alert  onClose={handleClose} severity={type}>{message}</Alert></Snackbar>
</form><br/><br/>
</Box>
);}

export default CompleteProfile;