import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, Card, CardMedia, CardContent } from '@mui/material';


function EULA() {
 return(
    <Box sx={{ m: 3, p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
   <Card style={{ border: "none", boxShadow: "none" }} sx={{ maxWidth: 345 }}>
     <CardMedia
       component="img"
       alt="app.MatchWorthee.com"
       height="100"
       image="./logoP.png"
     />
     <CardContent align="center">
       <Typography align="center" gutterBottom variant="p" style={{textAlign:'right', color:'#D8234C', textDecoration:'none'}} component={Link} to={'/Register'}>
       Register
     </Typography>
     </CardContent>
   </Card>
 </Box>
    <Typography variant="h2"  sx={{ mt: 2, mb: 2 }} align="center">Terms of Use</Typography>
    <Typography variant="body1" align="justify">
    These terms of use apply to your use of the website of Matchworthee
(www.Matchworthee.co - hereafter the &quot;Website&quot;) and Matchworthee mobile application
(iOS application / Android application - hereafter the &quot;App&quot;), provided by Anaitech Solutions
Private Limited (hereafter &quot;we&quot; or &quot;us&quot; or &quot;our&quot; or &quot;Matchworthee&quot;). Please go through
them carefully. If you object to anything in the terms of use or our privacy policy, please do
not use the website or the app or any other service we offer.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Your acceptance of the Terms of Use Agreement</Typography>
    <Typography variant="body1" align="justify">
    We reserve the right, at our discretion, to make changes to the terms of use and privacy
policy at any time. We may do this for a variety of reasons including to reflect changes in
new features, changes in business practices or requirements of the law. The most updated
version is available on our app and website with the date of last revision. Please check the
terms of use and privacy policy periodically for changes as all modifications are effective
upon publication. Continued use of the Matchworthee app and website after revision
indicates the user&#39;s acceptance of the terms of use. We will notify Matchworthee users
when modifications are made to the terms of use and/or privacy policy via our App.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Eligibility</Typography>
    <Typography variant="body1" align="justify">
    Matchworthee is a social platform with the purpose to suggest most compatible romantic
matches to our users. By creating an account on Matchworthee you hereby warrant the
following:
<List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You are at least 18 years of age" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You can form a binding contract with Matchworthee" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You are not barred from using the Service under your country’s laws or any other jurisdiction worldwide" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You do not have any unspent convictions, or are subject to any court order, relating to assault, violence, sexual misconduct or harassment" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You will not use our website or the App if you have been removed from Matchworthee" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You do not appear on the list of Specially Designated Nationals or face any other similar prohibition in any country" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="You will comply with this agreement and all applicable local, state, national and international laws, rules and regulations" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
  <ListItemText primary="We are entitled to remove your accounts with Matchworthee permanently if any of the above conditions are not met" />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Governing Law</Typography>
    <Typography variant="body1" align="justify">
    These terms of use and privacy policy shall be governed in all respects by the laws of India
and you agree to submit to the exclusive jurisdiction of the courts in New Delhi.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Registration and ‘Your Account’</Typography>
    <Typography variant="body1" align="justify">
    <List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If you want to use the Website or App, you first need to register. In order to register, you need to authenticate yourself using your mobile or email ID. After registering, you can create a personal account (&quot;Your Account&quot;), provided you are admitted. When creating Your Account, we will need your pictures. You are further invited to provide us with more information about yourself including but not limited to height, weight, marital status, relationship preference, education occupation and residential address. You email will serve as your identifier while contacting support." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Your Account is registered via your mobile number or email ID." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="The registration and creation of Your Account qualifies you as a user. Users can be suspended by us if we receive several complaints from other users." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Matchworthee does not accept any responsibility for the login credentials used to sign in to the service. You accept sole responsibility for all activities that occur under those credentials." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="By registering for Matchworthee, you grant us permission to send you SMS and WhatsApp notifications about your profile related activities." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If you feel your account has been compromised and been accessed by someone other than you, contact requesting for removal of your account from your registered email ID." />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Community Guidelines</Typography>
    <Typography variant="body1" align="justify">
    You agree to Matchworthee using emails, WhatsApp and other means to notify and engage
you with the service. Matchworthee users are expected to conduct themselves ethically and
honestly. Unbecoming behavior can lead to the termination of their membership. The below
etiquette is expected of Matchworthee users:
<List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will always provide accurate information about yourself to Matchworthee and other Matchworthee users you interact." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will upload only your own pictures directly clicked at the time of enrollment" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not post, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another user’s rights." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not use a robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, data mine, or in any way reproduce, reverse engineer or bypass the navigational structure or presentation of the service or its contents." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not use the Matchworthee app or any of its features for soliciting your own business activities or for commercial purposes." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not publish links to other websites." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not share details of Matchworthee login with another person." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will use your discretion while sharing personal information with other users on Matchworthee. You will exercise precautions for their privacy and safety of users to interact on Matchworthee. You may not publish any personal contact information on Your Account, such as your complete name, address, email address, telephone number, instant messaging contact details or social media website URLs or usernames. In addition, for your safety, you may not include personal contact information in messages to users who have not previously contacted you." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Your account will be terminated if you are found to misuse any features of Matchworthee." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Your photos will be reviewed for obscenity before being published on Matchworthee." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not upload viruses or other malicious code or compromise the security of the services offered by Matchworthee in any way." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not post content that is vulgar, defamatory, controversial, violative of copyrights and trademark rights, violative of any law that the publishing of such content may be subject to." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will use in a lawful, responsible and respectful manner, any information provided by another Matchworthee user" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not use the service to disrupt public order." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not infringe upon the normal running of Matchworthee or its infrastructure in any way." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will immediately cease contacting any user who asks you to stop contacting them." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You will not post, copy, modify, disclose or distribute via our Website or App any confidential information; or any other material which is subject to our or a third party's (intellectual property) rights, without first obtaining our or the relevant third party's prior written consent." />
 </ListItem>
 </List>

 You agree to compensate us for any claim or damages (including any legal fees in relation to
such claim or damages) demanded by a third party in respect of any matter relating to or
arising from any breach or suspected breach by you of these terms of use or the rights of a
third party. We reserve the right to issue warnings, suspend access to Your Account or
terminate Your Account, if we reasonably consider that you are in breach of these terms of
use.<br/><br/>
Matchworthee provides assistance and guidance through its customer care team. When
communicating with us, you agree to not be abusive, obscene, profane, offensive, sexist,
threatening, harassing, racially offensive, or indulge in any other inappropriate behavior. If
we feel that your behavior towards our customer care team or any other employee is
threatening or offensive, we reserve the right to immediately terminate your account
without any warning.<br/><br/>
Matchworthee reserves the right to investigate and/or terminate your account without any
refund or reimbursement if we feel you have misled Matchworthee or its users, or misused
the Service, or behaved in a way that Matchworthee regards as inappropriate or unlawful,
including actions or communications that occur off the service involving users you met
through Matchworthee.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Safety</Typography>
    <Typography variant="body1" align="justify">
    Matchworthee makes every effort to keep the information made available on the website
and app accurate and up to date, but we does not guarantee that the information is
accurate, complete or current. No rights can be derived from it. Any reliance on the
provided information is at your own risk. Though the company takes reasonable measurs to
verify its users, it does not conduct any criminal and/or other formal background checks of
its users and also does not attempt to verify the statements of its users. You accept that
there are risks interacting online or offline with other users, including matching and meeting
other users. We do not guarantee or verify the accuracy of information provided to you by
other users.<br/><br/>
We do not warrant that the website or app will be available uninterrupted and in a fully
operating condition. All content and services on the website or app are provided on an &quot;as
is&quot; and &quot;as available&quot; basis. Any decisions or actions taken by you on the basis of the
information provided on or via the website and app are at your sole discretion and risk.<br/><br/>
You will not post, or transmit to other users, any defamatory, inaccurate, abusive, obscene,
profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal
material, or any material that infringes or violates another party&#39;s rights.<br/><br/>
Matchworthee shall not collect data from minors. Should a Member lie about his/her date
of birth, and particularly if he/she fraudulently claims to be over 18 years of age, the parents
of the minor in question should inform Matchworthee of this by sending an email
requesting the data to be deleted. Matchworthee commits to deleting all data on the minor
in question as soon as possible.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Purchases, Cancellation, Returns and Refunds</Typography>
    <Typography variant="body1" align="justify">
    Since Matchworthee does not sell physical goods there is no cancellation or return.
Matchworthee users can make a payment to purchase digital goods which can be used for
soliciting a connection with other Matchworthee users. Digital goods could include
messages and subscriptions. New digital goods may be introduced, and older digital goods
may be modified or removed from time to time. Digital goods obtained through referrals,
will expire within 12 months.<br/><br/>
When you make an in-app purchase, you will be prompted to enter details for your account
with your Software Store (&quot;your IAP Account&quot;), and your IAP Account will be charged for the
in-app purchase in accordance with the terms disclosed to you at the time of purchase as
well as the general terms for in-app purchases that apply to your IAP Account. Some
Software Stores may charge you sales tax, depending on where you live. If you purchase an
auto-recurring periodic subscription through an in-app purchase, your IAP Account will
continue to be billed for the subscription until you cancel. After your initial subscription
commitment period, and again after any subsequent subscription period, your subscription
will automatically continue for an additional equivalent period, at the price you agreed to
when subscribing. If you do not wish your subscription to renew automatically, or if you
want to change or terminate your subscription, you will need to log in to your IAP account
and follow instructions to cancel your subscription, even if you have otherwise deleted your
account with us or if you have deleted the Matchworthee application from your device.<br/><br/>
Deleting your account on Matchworthee or deleting the Matchworthee application from
your device does not cancel your subscription; Matchworthee will retain all funds charged
to your IAP Account until you cancel your subscription through your IAP Account.<br/><br/>
In general, payments once made, are not refunded under any circumstances. In other
words, you do not have the option of cancelling or getting a refund for any payment. It
follows that you will not be getting a refund for any payment when your membership is
terminated. We may make an exception if a refund is requested within 14 days of the
transaction date if the payment was made in a currency other than INR, the paid goods
remain unused, and if the laws applicable in your jurisdiction provide for refunds. Such
refunds will be issued within 7 business days.<br/><br/>
Any payment related queries regarding payments made from iOS devices using your Apple
ID must be sent to Apple and not to Matchworthee. To request a refund, please visit
https://getsupport.apple.com. If you made the payment using your Google Play Store,
please contact the Matchworthee customer support by writing with your order details and
invoice. If you made a payment through the Website or through any other payment
gateway, please contact the Matchworthee customer support by writing with your order
details and invoice.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">License</Typography>
    <Typography variant="body1" align="justify">
    The rights granted by Matchworthee to the user are restricted to private and personal use.
As a user of Matchworthee, you grant Matchworthee a free-of-charge, non-exclusive,
international and permanent license for the use, reproduction, representation, modification
and translation of any basic intellectual property-related component (text, emojis, photos,
videos etc.) that it may provide through the App for the non-exclusive purpose of
communicating with other users on Matchworthee. Matchworthee can use such content in
any format on the Website, App, ads, other shielded areas accessible by other users and for
other internal purposes.<br/><br/>
Matchworthee reserves the right at any time to modify or discontinue, temporarily or
permanently, the service (or any part thereof) with or without notice. You agree that
Matchworthee shall not be liable to you or to any third party for any modification,
suspension or discontinuance of the service.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Deletion of Account</Typography>
    <Typography variant="body1" align="justify">
    Any user of Matchworthee may decide at any time and without notice to delete Your
Account. If this user wishes to use Matchworthee again, they will be required to register
once again.
<List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If a user deletes their account:" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Their profile will be removed from the list of profiles on Matchworthee" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="The user cannot reactivate their deleted profile" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Matchworthee reserves the right to terminate your membership, to suspend a profile or to disable access with respect to a breach of any of the terms with or without notice. Under any circumstances purchases made on Matchworthee will not be refundable." />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Copyright Infringement Claims</Typography>
    <Typography variant="body1" align="justify">
    You may not post, distribute, or reproduce in any way any copyrighted material,
trademarks, or other proprietary information without obtaining the prior written consent of
the owner of such proprietary rights. Without limiting the foregoing, if you believe that your
work has been copied and posted on the Service in a way that constitutes copyright
infringement, please provide us with the following information:
<List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="A description of the copyrighted work that you claim has been infringed" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="A description of where the material that you claim is infringing is located on the app" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Your full name, address, telephone number, and email address" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="A written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf" />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">External Links and Services</Typography>
    <Typography variant="body1" align="justify">
    The service may provide, or third parties may provide, links to other world wide web sites or
resources. Because Matchworthee has no control over such sites and resources, you
acknowledge and agree that Matchworthee is not responsible for the availability of such
external sites or resources, is not responsible or liable for any content, advertising, products
or other materials on or available from such sites or resources. You further acknowledge
and agree that Matchworthee shall not be responsible or liable, directly or indirectly, for
any damage or loss caused or alleged to be caused by or in connection with the use of, or
reliance upon, any such content, goods or services available on or through any such site or
resource. Please go through the terms and privacy policies of such sites carefully as you
would be bound by those during such interactions.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Limitation of Liability and Disputes</Typography>
    <Typography variant="body1" align="justify">
    <List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You are solely responsible for your interactions with other Matchworthee users. Matchworthee reserves the right to, but is not obliged to, monitor the resolution of any disputes arising between Matchworthee users. We are not obliged to become involved in any domestic or private disputes between users and do not provide any arbitration or settlement service. Matchworthee also reserves the right to take action, as deemed appropriate against errant users." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="In no event shall Matchworthee, its affiliates, employees, agents, licensors or partners be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the service, including without limitation, bodily injury, emotional distress, loss of use, loss of data, loss caused by a computer or electronic virus, loss of income or profit, loss of or damage to property, wasted management or office time, breach of contract or claims of third parties or other losses of any kind or character, even if Matchworthee has been advised of the possibility of such damages or losses, arising out of or in connection with the use of Matchworthee and/or any other damages resulting from communications or meetings with other users of this service or persons you meet through this service." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If you choose to invite someone to join our website or App, you may select a person directly from your contacts list on your device and send a text, email or message from your personal account. You understand and agree that you are responsible for any charges made against communications sent from your device." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="We will provide Matchworthee service to you with reasonable skill and care. We do not make any warranties or representations (neither express nor implied) with respect to the Website, App or the associated services." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You hereby expressly agree not to hold Matchworthee liable for any instruction, advice, or services delivered through our website or app. Matchworthee expressly disclaims any liability whatsoever for any damage, suits, claims, and/or controversies that arise or relate in any way to our website or app." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="You expressly understand and agree that Matchworthee will not be liable for direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from: a) the use or inability to use the website or app; b) the cost of procurement of substitute goods and services resulting from any goods, data, information or services obtained or messages received or transactions entered into through, from, or as a result of the website or app; c) unauthorized access to or alteration of your transmissions or data; d) statements or conduct of any user or third party on the website or app; e) your reliance on content data made available by us; or f) any other matter relating to the website or app" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Nothing in these terms of use shall exclude or limit our liability for our fraudulent misrepresentation or for death or personal injury resulting from our negligence or the negligence of our employees or agents." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="To the fullest extent possible by law, Matchworthee's maximum liability arising out of or in connection with the website or app or your use of our content, regardless of the cause of action (whether in contract, tort, breach of warranty, or otherwise), will not exceed greater than the fees you have paid to make purchases on Matchworthee." />
 </ListItem>
 </List>
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Indemnity</Typography>
    <Typography variant="body1" align="justify">
    To the full extent permitted under Indian laws, you agree to defend, indemnify and hold
harmless Matchworthee, it&#39;s officers, directors and employees from and against all
liabilities, demands, claims, damages, complaints, losses, costs, and expenses, including
attorney&#39;s fees, due to, arising out of, or relating in any way to your access to or use of the
services, your content, or your breach of this agreement.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Disclaimers</Typography>
    <Typography variant="body1" align="justify">
    Matchworthee does not represent or warrant that (a) any matches or profiles presented will
be compatible and/or guarantee success in finding a partner, or that (b) the service will be
uninterrupted, secure or error free, (c) any defects or errors in the service will be corrected,
(d) that any content or information you obtain on or through the service will be accurate, or
(e) any suggestions or feedback will be incorporated.<br/><br/>
Matchworthee takes no responsibility for any content that you or another user or third
party posts, sends or receives through the service. Any material downloaded or otherwise
obtained through the use of the service is accessed at your own discretion and risk.<br/><br/>
Matchworthee disclaims and takes no responsibility for any conduct of you or any other
user, on or off the service.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Advertising and External Links</Typography>
    <Typography variant="body1" align="justify">
    The service may provide, or third parties may provide, links to other world wide web sites or
resources. Because Matchworthee has no control over such sites and resources, you
acknowledge and agree that Matchworthee is not responsible for the availability of such
external sites or resources, is not responsible or liable for any content, advertising, products
or other materials on or available from such sites or resources. You further acknowledge
and agree that Matchworthee shall not be responsible or liable, directly or indirectly, for
any damage or loss caused or alleged to be caused by or in connection with the use of, or
reliance upon, any such content, goods or services available on or through any such site or
resource. Please go through the terms of use and privacy policies of such sites carefully as
you would be bound by those during such interactions.
    </Typography>
    <Typography variant="h3" sx={{ mt: 2, mb: 2 }} align="center">Miscellaneous</Typography>
    <Typography variant="body1" align="justify">
    <List sx={{ listStyleType: 'disc', pl: 4 }}>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="Matchworthee or Anaitech Solutions Private Limited can be acquired for a part or in whole by another company. This will be done without (written) notice." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If you breach these terms of use and we take no action against you, we will still be entitled to use our rights and remedies in any other situation where you breach these terms of use." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="If any part of these terms of use is disallowed or found to be void by any court or regulator, the other provisions shall continue to apply. We will adjust these terms of use as necessary, in which case the invalid terms will be replaced by valid terms that differ the least from the concerned invalid terms" />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="These terms of use are not intended to give rights to anyone except you and us." />
 </ListItem>
 <ListItem sx={{ display: 'list-item' }}>
 <ListItemText primary="We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at any time. The most updated version is available on our app and website. Please check the terms of use and privacy policy periodically for changes as all modifications are effective upon publication. Continued use of the Matchworthee app and website after revision indicates the user's acceptance of the terms of use." />
 </ListItem>
 </List>
 In case you come across suspicious activity, immediately notify us at team@matchworthee.com
    </Typography>
   
  </Box>
 )
}

export default EULA;
